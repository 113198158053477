import React, {useEffect, useState} from "react";
import {
  Row,
  Col,
} from "reactstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import moment from "moment";
import "moment-timezone";
import { Capacitor } from "@capacitor/core";
import MessageModal from "../../components/CommonForBoth/TopbarDropdown/MessageModal";
import { CommonLoader, LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import config from "../../config";

const Notifications = () => {
  const loginToken = LoginToken();
  const [data, setData] = useState();
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState();
  const [subject, setSubject] = useState();
  const toggle = () => {
    fetchMessages();
    setModal(!modal);
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchMessages = async () => {
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/messages?page=${currentPage}&hasExpiry=false`, {
       method: "GET",
       headers: { "Content-Type": "application/json", "x-api-key": loginToken },
     })
       .then((res) => {
         if (res.ok) {
           return res.json();
         }
         throw res;
         //const jsonRes = JSON.parse(res);
       })
       .then((data) => {
         if (data.results.success) {
           setData(data);
           setTotalPages(data.results.data.PageInformation.TotalPages);
         } else {
           // window.location.href = "/login";
         }
       });
  }
  useEffect(() => {
     fetchMessages();
  }, [currentPage]);

  const calcDateValue = (d) => {
    const t = new Date("January 1 1970 00:00:00-5:00");
    t.setSeconds(t.getSeconds() + parseFloat(d));
    const momentT = moment(t);

    return momentT.tz(moment.tz.guess()).format("M/D/YY");
  };


  const readMessage = (messageID) => {
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/message?messageId=${messageID}`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        // console.log(d);

      });
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {Breadcrumbs("Notifications", "Home", `/dashboard${TokenAppenditure()}`)}
            </Col>
          </Row>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            {!Capacitor.isNativePlatform() && <h4 className="mb-0 madeGentle">Notifications</h4>}
          </div>
          {data ? (
            <div className="card accountSettingsCard full-width mb-4">
            {data && data.results.data.Messages.length > 0 ? (
              data.results.data.Messages.map((e, i) => {
                return (
                  <>
                    <div
                      className="row-table pb-3 pt-3 px-3 border-bottom cursor-pointer"
                      onClick={() => {
                        setMessage(e.Message);
                        setSubject(e.Subject);
                        readMessage(e.MessageID);
                        setData(data);
                        toggle();
                      }}
                    >
                      <div className="row-group">
                        {/* <div className="box-icon mr-3">
                        <img
                          src={MusicalNotes}
                          alt="musical-notes"
                          style={{ maxHeight: 20 }}
                        />
                      </div> */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            maxWidth: 200,
                          }}
                        >
                          <h6
                            className="mb-0 topLeft"
                            style={{ display:'flex', fontSize: "0.93rem",flexDirection:'row', alignItems:'center' }}
                          >
                            {!e.IsRead && (
                              <div
                                style={{
                                  height: 10,
                                  width: 10,
                                  marginRight: "6px",
                                  borderRadius: "100%",
                                  background: "#f853bd",
                                }}
                              ></div>
                            )}
                            <span>{e.Subject}</span>
                          </h6>
                          {!e.Message.includes("<img") && (
                            <p
                              className="text-muted mb-0 Archivo message"
                              style={{
                                textTransform: "capitalize",
                                fontSize: 13,
                              }}
                              dangerouslySetInnerHTML={{ __html: !e.Message.includes("<img") && e.Message }}
                            >
                            </p>
                          )}

                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: 16,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 4,
                          textAlign: "right",
                        }}
                      >
                        <div>
                          <p className="mb-0" style={{fontSize:14}}>
                            {calcDateValue(e.CreatedDateTime)}
                          </p>
                        </div>
                      </div>
                    </div>
                    {data.results.data.Messages.length - 1 === i && (
                      <>
                      <div
                        className="px-4"
                        style={{
                          textAlign: "right",
                          marginTop: 16,
                          paddingBottom: 0,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <p className="px-4" style={{ fontSize: 14 }}>
                          Page {currentPage} of {totalPages}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            fontSize: 27,
                            marginTop: -10,
                            width: 72,
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className={`pagination-btn ${
                              currentPage > 1
                                ? ""
                                : "disabled-pagination-btn text-muted"
                            }`}
                            onClick={() => {
                              if (currentPage > 1) {
                                document.body.scrollTop = document.documentElement.scrollTop = 0;
                                setData();
                                setCurrentPage(parseInt(currentPage) - 1);
                              }
                            }}
                          >
                            {" "}
                            <MdOutlineChevronLeft />
                          </div>
                          <div
                            className={`pagination-btn ${
                              currentPage < totalPages
                                ? ""
                                : "disabled-pagination-btn text-muted"
                            }`}
                            onClick={() => {
                              if (currentPage < totalPages) {
                                document.body.scrollTop = document.documentElement.scrollTop = 0;
                                setData();
                                setCurrentPage(parseInt(currentPage) + 1);
                              }
                            }}
                          >
                            {" "}
                            <MdOutlineChevronRight />
                          </div>
                        </div>
                      </div>
                      </>
                    )}
                  </>
                );
              })
            ) : (
              <div className="p-3 text-center">
                <p className="text-muted">
                  No Notifications Yet. Check Back Soon!
                </p>
              </div>
            )}
          </div>
          ) : (
            <CommonLoader />
          )}
          <div style={{height:'500px'}}></div>
        </div>

      </div>
      <MessageModal
        subject={subject}
        message={message}
        modal={modal}
        toggle={toggle}
      />

    </React.Fragment>
  );
};;

export default Notifications;