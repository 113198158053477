import React, { useEffect, useState } from "react";
import { Progress } from "reactstrap";
import {Capacitor} from "@capacitor/core";
import Cookies from "js-cookie";
import Loader from "react-loader-spinner";
import { get } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { TokenAppenditure } from "../Utility/common";
import { API } from "aws-amplify";
import { listCompetitions, listPosts, listWinnerss } from "../../graphql/queries";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import MusicalNotes from "../../assets/images/ion-ios-musical-notes.svg";
import ReactPlayer from "react-player/soundcloud";
import queryString from "query-string";
import ReactPlayerFile from "react-player/file";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import Navbar from "../../components/HorizontalLayout/Navbar";
import Header from "../../components/HorizontalLayout/Header";
import { LoginToken, NoResults } from "../Utility/common";
import config from "../../config";

function ordinal_suffix_of(i) {
  if (i === "-") {
    return i;
  }
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

const MyEntries = (props) => {
  const { setCampaignType, campaignType, competitions, exclusiveCompetitions } =
    props;
  const [isPlaying, setIsPlaying] = useState(0);
  const [userEmail, setUserEmail] = useState(null);
  const [entriesPage, setEntriesPage] = React.useState(0);
  const [myEntries, setMyEntries] = useState();

  const columns = [
    {
      id: 0,
      label: "Name",
    },
    {
      id: 1,
      label: "End Date",
    },
    {
      id: 2,
      label: "Status",
    },
  ];

  const entriesColumns = [
    {
      id: 1,
      label: "Competition",
    },
    {
      id: 1,
      label: "Creator",
    },


  ];
  let history = useHistory();
  const location = useLocation();
  let params = queryString.parse(location.search);
  const loginToken = LoginToken();
  const [isDone, setIsDone] = useState(false);
  const [rowsPerEntriesPage, setRowsPerEntriesPage] = React.useState(10);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [activePostsArr, setActivePostsArr] = useState([]);
  const [activePostsPlaceArr, setActivePostsPlaceArr] = useState([]);
  const [pastPostsArr, setPastPostsArr] = useState([]);
  const skeletonData = ["", "", ""];
  const fetchMyEntries = async () => {
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/contest-entries`, {
      method: "GET",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        let activeArr =
          data.results.data.Active.Preffy;
        let pastArr = data.results.data.Past.Preffy.concat(
          data.results.data.Past.Songfluencer
        );
        setActivePostsArr(activeArr);
        setPastPostsArr(pastArr);
        setIsDone(true);

      });
  };


  function compareLikes(a, b) {
    if (a.likes < b.likes) {
      return -1;
    }
    if (a.likes > b.likes) {
      return 1;
    }
    return 0;
  }

  const handleChangeEntriesPage = (event, newPage) => {
    setEntriesPage(newPage);
  };

  const handleChangeRowsPerEntriesPage = (event) => {
    setRowsPerEntriesPage(+event.target.value);
    setEntriesPage(0);
  };

  const [myCompetitionsArr, setMyCompetitionsArr] = useState([]);
  const returnFormattedDate = (d) => {
    const D = new Date(d);
    return `${D.getMonth() + 1}/${D.getDate()}/${D.getFullYear()}`;
  };

  useEffect(() => {
    fetchMyEntries();
  }, []);

  const returnTable = (title, arr) => {

    return (
      <div className="ml-md-4 mb-2 ">
        <div className="table-responsive bg-white rounded card">
          <Paper style={{ width: "100%", minHeight: 250 }}>
            <h5 className="mb-0 mt-2 mx-3 title pt-2 pb-2 fw-bold">
              {title}
            </h5>
            {!isDone ? (
              <div style={{ height: 40 }}>
                <div
                  style={{
                    height: 190,
                    margin: "auto",
                    display: "flex",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Loader type="Bars" color="#f853bd" height={22} width={55} />
                </div>
              </div>
            ) : (
              <>
                {arr.filter(e=> !e._deleted).length > 0 ? (
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {entriesColumns.map((column) => {
                            return (
                              <TableCell key={column.id}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {column.label}
                                </div>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>

                      <TableBody style={{ minHeight: 100 }}>
                        {arr
                          .slice(
                            entriesPage * rowsPerEntriesPage,
                            entriesPage * rowsPerEntriesPage +
                              rowsPerEntriesPage
                          )
                          .map((row, i) => {
                            if (row && !row._deleted) {
                              return (
                                <Link style={{display:'contents'}} to={`/contestdetail?PostID=${row.postId}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`}>
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={i}
                                    style={{ cursor: "pointer" }}

                                  >
                                    <TableCell
                                      key={i}

                                    >
                                      <div
                                        style={{ marginTop: 3 }}
                                        className="mobile-creator-col "
                                      >
                                        {row.name}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      key={i}

                                    >
                                      <div
                                        style={{ marginTop: 3 }}
                                        className="mobile-creator-col "
                                      >
                                        @{row.creator}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                </Link>
                              );
                            }
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div
                      className="p-2 py-5"
                      style={{
                          display: "flex",
                          textAlign: "center",
                          flexDirection: "column",
                      }}
                  >
                      <h5 className="madeGentle">No Results Found.</h5>
                      <h6 className="text-muted">
                          No results found! In the meantime, earn money by entering
                          some of our ongoing{" "}
                          <a
                          className="text-pink"
                          href={`/contests${Capacitor.isNativePlatform() ? `?token=${LoginToken()}` : ""}`}
                          >
                          contests
                          </a>
                          .
                      </h6>
                  </div>
                )}
              </>
            )}
            {arr.filter(e=> !e._deleted).length > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={arr.length}
                rowsPerPage={rowsPerEntriesPage}
                page={entriesPage}
                onChangePage={handleChangeEntriesPage}
                onChangeRowsPerPage={handleChangeRowsPerEntriesPage}
              />
            )}

          </Paper>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="" style={{display:'flex',flexDirection:'column'}}>
        {returnTable("Active Entries", activePostsArr)}
        {returnTable("Past Entries", pastPostsArr)}
      </div>
    </React.Fragment>
  );
};;;

export default MyEntries;
