import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import Cookies from "js-cookie";
import { Row, Col, Alert, Container ,CardBody,Card,} from "reactstrap"
import { useLocation, useHistory, Link } from "react-router-dom";
import queryString from "query-string";
import { Capacitor } from '@capacitor/core';
import { Toast } from "@capacitor/toast";
// Redux
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';

import Footer from "../../components/HorizontalLayout/Footer"
import { Preferences } from '@capacitor/preferences';
import { connect } from "react-redux"
import { withRouter, } from "react-router-dom"
import logo from "../../assets/images/whiteg.png";
import Loader from "react-loader-spinner";
import { SplashScreen } from '@capacitor/splash-screen';
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { TbAlertCircleFilled } from 'react-icons/tb';
import config from "../../config";

//Import config
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const Login = (props) => {
  const [userID, setUserID] = useState();
  const [email, setEmail] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const location = useLocation();
  const {setCardHeight, setBorderRadius, setWelcomeState} = props;
  let params = queryString.parse(location.search);
  const [form, setForm] = useState(1);
  const history = useHistory();
  const [localToken, setLocalToken] = useState();
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleUserIDChange = (e) => {
    setUserID(e.target.value);
  };
   const handleEmailChange = (e) => {
     setEmail(e.target.value);
   };
  const [password, setPassword] = useState();
   // handleValidSubmit
   const handleValidSubmit = (event, values) => {
    // console.log(values)

  }
  const nullEntry = [];
  const [registrationToken, setRegistrationToken] = useState();
  const [notifications, setnotifications] = useState(nullEntry);
  useEffect(()=>{
    if (Capacitor.isNativePlatform()) {
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
              // showToast('Push Notification permission denied');
            }
            else {
              // showToast('Push Notification permission granted');
              register();
            }
          });
        }
        else {
          register();
        }
      });
    }

    },[])

    const register = () => {
        // console.log('Initializing HomePage');

        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token) => {
                if (!registrationToken) {
                  setRegistrationToken(token.value);
                }
                // showToast(`${token.value}`);
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error) => {
                alert('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification) => {
                setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification) => {
                setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
            }
        );
    }

    const showToast = async (msg) => {
        await Toast.show({
            text: msg
        })
    }
  const [error, setError] = useState("");
  const getLoginTokenState = async ()=>{
    const { value } = await Preferences.get({ key: 'LoginToken' });
    const tok = value  ? value : "none";
    console.log(`Hello ${value}!`);
    setLocalToken(tok);
    return tok;
  }

  const loginAttempt = () => {
    setIsUploading(true);
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/auth/login`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: userID, password:password})
      }
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setError(JSON.parse(errText.message).messages[0] + ". Having trouble logging in? Contact outreach@genni.com");
            setIsUploading(false);
          });
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then(async (data) => {
        if (data.error) {
          setError(`${data.messages[0]} Having trouble logging in? Contact outreach@genni.com`);
        } else {
          await Cookies.set("ContactDetail", JSON.stringify(data.contactDetail));
          await Cookies.set("LoginToken", data.data.token);
          await Preferences.set({
            key: 'LoginToken',
            value: data.data.token,
          });
          const redirectLink = Capacitor.isNativePlatform() ? `/dashboard?token=${data.data.token}` : "/dashboard"
          window.location.href = redirectLink;
        }
      });
  };

  const linkEmail = () => {
    fetch(
      `https://api.genni.com/username?username=${encodeURIComponent(email)}&x-api-key=${localToken}`,
      { method: "POST" }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then(async (data) => {
        // console.log("data", data);
        setForm(3);
      })
      .catch((error) => {
        setError("Duplicate or Invalid Credentials");
      });
  };


  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    getLoginTokenState()

    if (localToken && localToken !== "none"){
      fetch(
        `${config.genni.marketing_portal.API_KEY}/creatorapi/check`,
        { method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key":localToken },

         }
      )
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          }else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setError(JSON.parse(errText.message).messages[0]);
          });
        }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then(async (data) => {
          // console.log("data",data);
          if (data.results.success) {
            history.push(`/dashboard${Capacitor.isNativePlatform() ? `?token=${localToken}` : ""}`);

          }
        });

    }
    return function cleanup() {
      document.body.className = "";
    };
  }, [localToken]);

  // console.log(localToken, "loca");


  const returnForm = () => {
    if (form === 1) {
      return (
        <div className='container' style={{maxWidth:300}}>
          <div className="text-center">
            {error && password && <Alert color="danger">{error}</Alert>}
            <h3 className='madeGentle mt-1 margin-auto text-center mb-3'>Log In</h3>

            {/* {getLoginTokenState() ? showToast(getLoginTokenState()) : ""} */}
          </div>
          <div className="mt-0">
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.error && typeof props.error === "string" ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}

              <div className="mb-3">
                <AvField
                  name="userID"
                  label="Email"
                  value={userID}
                  onChange={handleUserIDChange}
                  validate={{
                    required: { value: true },
                  }}
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  required
                />
              </div>

              <div className="mb-3">
                <div className="float-end">
                  <Link to="/forgot-password" className="text-muted">
                    Forgot password?
                  </Link>
                </div>
                <AvField
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  className="form-control"
                  label="Password"
                  errorMessage="Invalid Password"
                  value={password}
                  onChange={handlePasswordChange}
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>



              <div className="mt-3">
                <button
                  className="btn mobile-btn btn-pink text-white w-100 waves-effect waves-light"
                  onClick={loginAttempt}
                >
                  {isUploading ? (
                    <Loader type="Bars" color="#fff" height={20} width={40} />
                  ) : (
                    "Log In"
                  )}
                </button>
              </div>
              <div className="mt-4 text-center">
                <p className="mb-0 d-flex text-center jc-center ">
                  Don't have an account?{" "}
                  <div
                    onClick={()=>{setWelcomeState("hello");setCardHeight("100vh"); setBorderRadius(0);}}
                    className="fw-medium text-pink cursor-pointer mx-1"
                  >
                    {" "}
                    Sign Up{" "}
                  </div>{" "}
                </p>
              </div>
            </AvForm>
          </div>
        </div>
      );
    } else if (form ===2) {
      return (
        <>
          <div className="text-center mt-2">
            <h5 className="text-pink madeGentle">Link A Unique Email To Your Account</h5>
            <p className="text-muted">This email will be used to help you login going forward.</p>
          </div>
          <div className="p-2 mt-4">
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.error && typeof props.error === "string" ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}

              <div className="mb-3">
                <AvField
                  name="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  validate={{
                    required: { value: true },
                  }}
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  required
                />
              </div>


              <div className="mt-3">
                <p className="text-danger">{error}</p>
                <button
                  className={`btn gradient-navy text-white w-100 waves-effect waves-light ${!validateEmail(email) && 'disabled'}`}
                  onClick={()=>{
                    if(validateEmail(email)){
                      linkEmail();
                    }

                  }}
                >
                 Submit
                </button>
              </div>


            </AvForm>
          </div>
        </>
      );
    } else if (form === 3) {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "-30px",
            }}
          >
            <i
              className="mdi mdi-check-decagram"
              style={{ fontSize: 70, color: "#162638" }}
            />
            Thank you for registering! We've sent an email to verify your
            account.
          </div>
        </>
      );
    }
  }

  return (
    <>
      {returnForm()}

    </>

  );
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter((Login))

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}