import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import defaultImg from "../../assets/images/icon.png";
import {
  Row,
  Col,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper
} from "@material-ui/core";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { MdCheckCircle, MdEdit, MdEditDocument, MdEditNote, MdUpdate, MdWarning } from "react-icons/md";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as moment from "moment";
import { LoginToken, TokenAppenditure, Breadcrumbs, ValidateEmail, CommonLoader } from "../Utility/common";
import { TbAlertCircleFilled } from "react-icons/tb";
import config from "../../config";

function range(start, thisYear) {
  const arr = [];
  for (let index = start; index < thisYear; index++) {
    arr.push(index);
  }
  return arr;
}

function getFormattedDate(d) {
  const date = new Date(d);
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;
  let day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;
  return month + "/" + day + "/" + year;
}

const Payment = ({ isAdminPage }) => {
  const [errorMessage, setErrorMessage] = useState();
  const [PayPalEmail, setPayPalEmail] = useState();
  const [contactNotes, setContactNotes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [managedCreators, setManagedCreators] = useState();
  const [isManager, setIsManager] = useState();
  const [isShallowContact, setIsShallowContact] = useState();
  const [paymentEmailHistory, setPaymentEmailHistory] = useState();
  let params = queryString.parse(location.search);
  const loginToken = LoginToken();
  const [changeModal, setChangeModal] = useState(false);
  const toggleChangeModal = () => {
    setChangeModal(!changeModal);
  }
  useEffect(() => {
    if (!paymentEmailHistory) {
      fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/vendor?x-api-key=${loginToken}`, {
        method: "GET",
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          // console.log("payment data",data);
          setIsLoading(false);
          setPaymentEmailHistory(data.results.data.PaymentEmailHistory)
          setPayPalEmail(data.results.data.ContactInformation.EmailAddress_Payment ? data.results.data.ContactInformation.EmailAddress_Payment : "");
        });
    }


    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/switch`, {
      method: "GET",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          window.location.href = "/login";
        }
      })
      .then(async (data) => {
        // console.log("switch data",data);
        setIsManager(data.contactDetail.contactType === "manager");
        setIsShallowContact(data.contactDetail.logInUsername === "");
        setManagedCreators(data.results.LinkedAccounts);
      })
  }, [paymentEmailHistory]);

  const [successMessage, setSuccessMessage] = useState();
  const [successSubMessage, setSuccessSubMessage] = useState();
  const [emailSent, setEmailSent] = useState(false);

  const handleContactNotesChange = (e) => {
    setContactNotes(e.target.value);
  };

  const handlePayPalEmailChange = (e) => {
    setPayPalEmail(e.target.value);
  };

  const updatePaymentInfo = (e) => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/contact/requestchangepaymentinfo`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({ email: PayPalEmail })
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setErrorMessage();
        setSuccessMessage(d.data.message);
        setPaymentEmailHistory();
        setSuccessSubMessage(d.data.notification_message_info);
      }).catch(error => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setSuccessMessage("");
        setErrorMessage("Please make sure all fields are properly filled out.");
      });
  };

  const resendPaymentEmail = (e) => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/resend-payment-email`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({ uid: e.paymentEmailUid, contactPaymentEmailId: e.contactPaymentEmailId })
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        setEmailSent(true);
      }).catch(error => {

      });
  };

  return (
    <React.Fragment>
      <div className={`page-content ${isAdminPage ? 'mt-0 pt-0' : ''}`}>
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {!isAdminPage && (
                <>
                  {Breadcrumbs("Payment Information", "Account Settings", `/settings${TokenAppenditure(), true}`)}
                </>
              )}
              {isLoading ? (
                <>
                  <CommonLoader />
                </>
              ) : (
                <>
                  {!isShallowContact ? (
                    <AvForm className="card p-3">
                      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                      {successMessage ? (
                        <>
                          <div className="flex flex-col justify-center text-center py-2">
                            <div className="rounded-full" style={{ background: 'rgb(249, 250, 251)', height: 68, width: 68, borderRadius: '100%', display: 'flex', justifyContent: 'center', margin: "AUTO", alignItems: 'center' }}>
                              <MdWarning size={36} color="rgb(239, 68, 68)" />
                            </div>
                            <h5 className="mt-3">{successMessage}</h5>
                            <p className="mt-0 font-semibold" style={{ fontSize: '1.1rem' }}>
                              Please click the confirmation link in the email to approve the change.
                            </p>

                          </div>
                        </>
                      ) : (
                        <>
                          <div className="page-title-box d-flex flex-col">
                            <h4 className="mb-0 madeGentle">Payment Information</h4>
                            <p className="text-muted">Genni pays creators via PayPal. If you don't have an account, please sign up at PayPal.com.</p>
                          </div>
                          <div className="campaigns">

                            <div className="campaign">
                              <div className="campaign-title">
                                <h6 className="text-black ">PayPal Email</h6>
                              </div>
                              <AvField
                                name="PayPalEmail"
                                value={PayPalEmail}
                                onChange={handlePayPalEmailChange}
                                type="text"
                                className="inputField mb-3 "
                                errorMessage="Invalid Email"
                              />
                            </div>

                            <div className="campaign">
                              <div
                                className={`btn btn-pink gradient-pink full-width float-right ${!ValidateEmail(PayPalEmail) && "disabled"}`}
                                style={{ width: "100%" }}
                                onClick={() => {
                                  if (ValidateEmail(PayPalEmail)) {
                                    if (isManager) {
                                      toggleChangeModal();
                                    } else {
                                      updatePaymentInfo()
                                    }
                                  }
                                }
                                }
                              >
                                Submit
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </AvForm>
                  ) : (
                    <Paper className="card mb-3 p-3 pb-0" style={{ overflow: 'auto' }}>
                      <div className="page-title-box d-flex flex-col">
                        <AvForm>
                          <div className="campaigns">
                            <div className="campaign">
                              <div className="campaign-title">
                                <h4 className="text-black madeGentle">PayPal Email</h4>
                              </div>
                              <AvField
                                name="PayPalEmail"
                                value={PayPalEmail}
                                onChange={handlePayPalEmailChange}
                                type="text"
                                className="inputField mb-3 disabled"
                                errorMessage="Invalid Email"
                              />
                            </div>
                          </div>
                        </AvForm>
                      </div>
                    </Paper>
                  )}
                </>
              )}


              <Paper className="card" style={{ overflow: 'auto' }}>
                <h4 className="mb-3 madeGentle p-3 pb-0">Payment History</h4>
                <Table aria-label="sticky table bg-white" style={{ overflow: 'auto' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          Payment Email
                        </div>

                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          Date Activated
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          Status
                        </div>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentEmailHistory && paymentEmailHistory.map(e => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          style={{ cursor: "pointer" }}
                          tabIndex={-1}
                        >

                          <TableCell >
                            {e.email}
                          </TableCell>
                          <TableCell>
                            {e.confirmedResponse !== "Pending" &&
                              getFormattedDate(e.activatedDate)
                            }

                          </TableCell>
                          <TableCell>
                            {e.confirmedResponse}

                          </TableCell>
                          {e.confirmedResponse === "Pending" ? (
                            <TableCell >
                              <div className={`btn ml-2 btn-pink ${emailSent && "disabled border-none"}`} onClick={() => {
                                if (!emailSent) {
                                  resendPaymentEmail(e.resendEmailInfo)
                                }
                              }}>Resend Email</div>
                            </TableCell>
                          ) : (<TableCell></TableCell>)}


                        </TableRow>
                      )
                    })}

                  </TableBody>
                </Table>
              </Paper>
            </Col>
          </Row>
        </div>
      </div>
      <Modal isOpen={changeModal} toggle={toggleChangeModal}>
        <ModalHeader>
          This will apply to the following creators
        </ModalHeader>
        <ModalBody style={{ paddingTop: 0 }}>
          {managedCreators &&
            managedCreators.map((e, i) => {
              if (!e.LogInUsername) {
                return (
                  <div
                    className="border-bottom d-flex full-width pb-2 pt-2 mt-0"
                    style={{ justifyContent: "space-between" }}

                  >
                    <div style={{ display: "flex", alignItems: "center", }}>
                      <div>
                        <img
                          src={e.ProfileImageUrl ? e.ProfileImageUrl : defaultImg}
                          style={{
                            height: 35,
                            width: 35,
                            borderRadius: "100%",
                            objectFit: "cover",
                            marginRight: 8,
                          }}
                        />
                        {e.UnreadMessageCount > 0 && (
                          <div
                            style={{
                              position: "absolute",
                              height: 13,
                              width: 13,
                              background: "#f853bd",
                              borderRadius: "100%",
                              top: 10,
                              left: 50,
                              border: "1px solid #fff",
                            }}
                          ></div>
                        )}
                      </div>
                      <span className="overflow-ellipsis" style={{ maxWidth: '130px' }}>
                        {e.DisplayName}
                      </span>
                      {e.InviteCount > 0 && (
                        <span className="badge bg-soft-pink rounded-pill ms-1">
                          {e.InviteCount} Invites
                        </span>
                      )}
                      {e.AccountType === "Manager" && (
                        <span className="badge bg-soft-pink rounded-pill ms-1">
                          Manager
                        </span>
                      )}
                    </div>

                  </div>
                );
              }

            })}
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-light" onClick={toggleChangeModal}>Cancel</div>
          <div className="btn btn-pink" onClick={() => { updatePaymentInfo(); toggleChangeModal(); }}>Confirm</div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};;

export default Payment;
