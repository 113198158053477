import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { FaBell } from "react-icons/fa";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import Cookies from "js-cookie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import "moment-timezone";
import MessageModal from "./MessageModal"
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {Capacitor} from "@capacitor/core";

//i18n
import { withTranslation } from "react-i18next"
import { LoginToken } from "../../../pages/Utility/common"
import { MdAccessTime } from "react-icons/md";
import config from "../../../config";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const {isBottomNav} = props;
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState();
  const [subject, setSubject] = useState();
  const location = useLocation();
  let params = queryString.parse(location.search);
  const loginToken = LoginToken(); // => 'value'
  const [messages, setMessages] = useState();
  const [unreadMessagesNumber, setUnreadMessagesNumber] = useState(0);
  const toggle = () => {
    fetchMessages();
    setModal(!modal);
  }
  const calcDateValue = (d) => {
    const t = new Date("January 1 1970 00:00:00-0:00");
    t.setSeconds(t.getSeconds() + parseFloat(d));
    const momentT = moment(t);
    return momentT.tz(moment.tz.guess()).format("MMM D hh:mmA z");
  };

  const fetchMessages = async () => {
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/messages`, {
       method: "GET",
       headers: { "Content-Type": "application/json", "x-api-key": loginToken },
     })
       .then((res) => {
         if (res.ok) {
           return res.json();
         }
         throw res;
         //const jsonRes = JSON.parse(res);
       })
       .then((data) => {
         if (data.results.success) {
           setMessages(data);
           setUnreadMessagesNumber(
            data.results.data.Messages.filter((e) => e.IsRead === false).length
          );
        } else {
           // window.location.href = "/login";
         }
       });
  }
  useEffect(() => {

    fetchMessages();
  }, []);

  const readMessage = (messageID) => {
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/message?messageId=${messageID}`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        // console.log(d);

      });
  }
  if (isBottomNav) {
    return (
      <>
        <FontAwesomeIcon icon={faBell}  style={{ height:20, marginBottom:5}}>

        {unreadMessagesNumber && unreadMessagesNumber >= 1 ? (
                <span className="badge bg-pink rounded-pill" style={{fontSize: '7px',position: 'absolute',fontStyle: 'normal',left: '51.5%', right: '0', maxWidth: '12px'}}>
                  {unreadMessagesNumber && unreadMessagesNumber}
                </span>
              ) : ""}
        </FontAwesomeIcon>

      </>
    )
  } else {
    return (
      <>
        <Dropdown
          isOpen={menu}
          toggle={() => {
            setMenu(!menu);
          }}
          className="dropdown d-inline-block"
          tag="li"
        >
            <DropdownToggle
              className="btn header-item noti-icon waves-effect"
              tag="button"
              id="page-header-notifications-dropdown"
            >
              <FaBell size={22} color="#162638" />
              {unreadMessagesNumber && unreadMessagesNumber >= 1 ? (
                <span className="badge bg-pink rounded-pill">
                  {unreadMessagesNumber && unreadMessagesNumber}
                </span>
              ) : ""}
            </DropdownToggle>
          <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 font-size-16 madeGentle">
                    {" "}
                    {props.t("Notifications")}{" "}
                  </h6>
                </Col>
              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>
              {messages && messages.results.data.Messages.length > 0 ? (
                messages.results.data.Messages.map((e) => {

                  return (
                    <div
                      className="text-reset notification-item border-bottom cursor-pointer"
                      onClick={() => {
                        setMessage(e.Message);
                        setSubject(e.Subject);
                        readMessage(e.MessageID);
                        if (!e.IsRead) {
                          setUnreadMessagesNumber(unreadMessagesNumber - 1);
                        }
                        setMessages(messages);
                        toggle();
                      }}
                    >
                      <div className="d-flex align-items-start">
                        <div
                          className="flex-1"
                          style={{ overflow: "hidden", whiteSpace: "nowrap" }}
                        >
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {!e.IsRead && (
                              <div
                                style={{
                                  height: 10,
                                  width: 10,
                                  marginRight: "4px",
                                  borderRadius: "100%",
                                  background: "#f853bd",
                                }}
                              ></div>
                            )}
                            <h6
                              className="mt-0 mb-0 subject"
                              style={{ alignItems: "center" }}
                            >
                              {e.Subject}
                            </h6>
                          </div>
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">
                              <div
                                className="message"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    !e.Message.includes("<img") && e.Message,
                                }}
                              ></div>
                            </p>
                            <p className="mb-0">

                              {calcDateValue(e.CreatedDateTime)}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="notification-item text-reset p-3 text-center">
                  {" "}
                  <p className="text-muted ">No Recent Notifications. Check Back Soon!</p>
                </div>
              )}
            </SimpleBar>
            <div className="p-2 border-top d-grid">
              <Link
                className="btn btn-sm btn-link font-size-14 text-center text-pink"
                to="/notifications"
              >
                <i className="uil-arrow-circle-right me-1 "></i>{" "}
                {props.t("View all")}{" "}
              </Link>
            </div>
          </DropdownMenu>
        </Dropdown>
        <MessageModal
          subject={subject}
          message={message}
          modal={modal}
          toggle={toggle}
        />
      </>
    );
  }

};

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
}