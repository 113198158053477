import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm } from "availity-reactstrap-validation";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Row, Col, Alert } from "reactstrap";
import { LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";
import config from "../../config";

const Tax = () => {
  const [errorMessage, setErrorMessage] = useState();
  const [file, updateFile] = useState(null);
  const [fileName, updateFileName] = useState("Upload Image");
  const [fileResult, updateFileResult] = useState(null);
  const location = useLocation();
  const loginToken = LoginToken(); // => 'value'
  const [isLoading, setIsLoading] = useState();
  const [data, setData] = useState();
  function handleImageChange(event) {
    const {
      target: { value, files },
    } = event;

    const fileForUpload = files[0];
    // console.log("file", fileForUpload);
    if (
      fileForUpload.type.includes("pdf") ||
      fileForUpload.type.includes("jpeg") ||
      fileForUpload.type.includes("png")
    ) {
      let reader = new FileReader();
      let url = reader.readAsDataURL(fileForUpload);

      reader.onloadend = function (e) {
        updateFileResult([reader.result]);
      }.bind(this);
      if (fileForUpload) {
        updateFileName(fileForUpload.name.split(".")[0]);
        updateFile(fileForUpload || value);
      }
    } else {
      setErrorMessage("Invalid File");
    }
  }
  const finishUpload = async () => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(
        `${config.genni.marketing_portal.API_KEY}/creatorapi/tax-save`,
        {
          method: "POST",
          headers: { "x-api-key": loginToken },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        setErrorMessage(JSON.parse(errorText).messages[0]);
        throw response;
      }

      const data = await response.json();
      if (!data.error) {
        window.location.reload();
      }
    } catch (err) {
      console.error("Upload error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTaxDoc = () => {
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/tax-delete`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (!data.error) {
          window.location.reload();
        } else {
          // window.location.href = "/login";
        }
      });
  };
  useEffect(() => {
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/tax`, {
      method: "GET",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        setData(data);
      });
  }, []);
  // console.log(data);
  // console.log(fileResult);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {Breadcrumbs(
                "Tax Documents",
                "Account Settings",
                `/settings${TokenAppenditure()}`,
                true
              )}

              {/* <div className="splashy-banner mt-1 card">
                <div className="left-splashy">
                  <img src={GroupIMG} style={{height: 60}} />
                </div>
                <div className="px-4 py-3">
                  <h5 className="mb-1">Upload W-9</h5>
                  <p className="text-muted mb-1">By Jan 2nd</p>
                  <div className="btn btn-pink mb-1">Upload Now</div>
                </div>
              </div> */}
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 madeGentle">Required Tax Documents</h4>
              </div>
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              {data && data.results.TaxForm_IsUploaded && (
                <Alert color="success">
                  You have successfully uploaded your tax documents.
                </Alert>
              )}
              <div className="card p-3">
                {data && data.results.TaxForm_IsUploaded && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <a
                      className="btn btn-pink full-width Archivo"
                      href={`${data.results.TaxForm_URL}`}
                      target="_blank"
                      style={{ width: "100%" }}
                    >
                      View
                    </a>
                    <div
                      className="btn btn-outline-pink full-width Archivo"
                      style={{ width: "100%" }}
                      onClick={() => {
                        deleteTaxDoc();
                      }}
                    >
                      Delete
                    </div>
                  </div>
                )}
                <br />
                {data && (
                  <div
                    className="Archivo"
                    dangerouslySetInnerHTML={{
                      __html: data.results.TaxFormInformationHTMLMessage,
                    }}
                  />
                )}

                <br />
                <AvForm>
                  <div className="campaigns">
                    <div className="campaign">
                      <div className="campaign-title">
                        <h5 className="text-black madeGentle">
                          Upload Tax Document (JPG, PNG, or PDF FILE)
                        </h5>
                      </div>
                    </div>
                    <div className="campaign">
                      {file ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {fileResult &&
                          fileResult[0].includes("application/pdf") ? (
                            <embed src={fileResult} width="40px" />
                          ) : (
                            <img
                              src={fileResult}
                              alt="file"
                              style={{ width: 40 }}
                            />
                          )}

                          <div className="mt-2">
                            <div
                              className="btn btn-pink full-width float-right"
                              onClick={() => {
                                finishUpload();
                              }}
                            >
                              {isLoading ? (
                                <Loader
                                  type="Bars"
                                  color="#fff"
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                "Submit"
                              )}
                            </div>
                            <div
                              className="btn btn-secondary full-width float-right my-3"
                              onClick={() => {
                                updateFile(null);
                                updateFileResult(null);
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <label
                            htmlFor="file-upload"
                            className="btn btn-pink gradient-pink full-width float-right"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "16px",
                            }}
                          >
                            <i className="mdi mdi-cloud-upload mr-2"></i>Upload
                            File
                          </label>
                          <input
                            type="file"
                            id="file-upload"
                            onChange={handleImageChange}
                            style={{ width: "140px", display: "none" }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </AvForm>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Tax;
