import React, { useEffect, useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Container, Alert } from "reactstrap";
import Feedback from "../Feedback";
import { LoginToken, ValidateEmail } from "../Utility/common";
import config from "../../config";

const Contact = () => {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("Contact Us");
  const [name, setName] = useState("");

  const [senderEmail, setSenderEmail] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();
  const [error, setError] = useState("");
  let params = queryString.parse(location.search);
  const loginToken = LoginToken();
  const [data, setData] = useState();

  useEffect(() => {
    if (params.type === "trackers" && subject !== "Trackers Inquiry") {
      setSubject("Trackers Inquiry");
      setMessage("Hi! I would like to be set up with a Genni account to use trackers.")
    }
  }, []);

  const handleToChange = (e) => {
    setTo(e.target.value);
  };

  const handleSenderEmailChange = (e) => {
    setSenderEmail(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const [successMessage, setSuccessMessage] = useState();

  const sendEmail = () => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/public/send-contact-form`,
      { method: "POST",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify({emailFrom: senderEmail, subject: subject, message: message})
       }
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setSuccessMessage();
            setError("There was an error.");
          });
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (data.results.success) {
          // window.location.href = `/`;
          setError("");
          // console.log("success",data.data.results.FormSubmissionResponseMessage);
          setSuccessMessage(data.results.message);
        } else {
          setSuccessMessage();
          setError(data.results.message);
        }
      });
  };

  const isReadyToSubmit = ValidateEmail(senderEmail) && message;

  return (
    <React.Fragment>
      <div className="bg-pink" style={{height:5, width:'100%'}}>

      </div>
      <div className="page-content" style={{marginTop:'30px'}}>
        <Container className="bg-white p-3 card pb-4">
          {successMessage && <Alert color="success">{successMessage}</Alert>}
          {error && <Alert color="danger">{error}</Alert>}

          <>
            <p
              className="madeGentle pb-1"
              style={{ fontSize: 24, marginBottom: 0 }}
            >
              Contact Us
            </p>
            <AvForm>
              <div className="campaigns">

                <div className="campaign mt-3">
                  <div className="campaign-title">
                  <h6 className="">Subject</h6>

                  </div>
                  <AvField
                    name="subject"
                    type="subject"
                    className="inputField Archivo"
                    errorMessage="Invalid Subject"
                    value={subject}
                    onChange={handleSubjectChange}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>


                <div className="campaign mt-3">
                  <div className="campaign-title">
                    <h6 className="">Your Email</h6>
                  </div>
                  <AvField
                    name="email"
                    type="email"
                    className="inputField Archivo"
                    errorMessage="Invalid Email"
                    value={senderEmail}
                    onChange={handleSenderEmailChange}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>

                <div className="campaign">
                  <div className="campaign-title">
                    <h6 className="">Message</h6>
                  </div>
                  <textarea
                    className="inputField Archivo"
                    name="message"
                    style={{ minWidth: "100%",minHeight:150 }}
                    value={message}
                    onChange={handleMessageChange}
                  ></textarea>
                </div>
                {!successMessage ? (
                  <div className="campaign">
                    <div
                      className={`btn btn-pink gradient-pink full-width float-right mt-3 ${!isReadyToSubmit && "disabled"}`}
                      style={{ width: "100%" }}
                      onClick={()=>{
                        if (isReadyToSubmit) {
                          sendEmail()
                        }
                      }}
                    >
                      Submit
                    </div>
                  </div>
                ) : (
                  <div className="campaign">
                    <div
                      className="btn full-width float-right"
                      style={{ width: "100%", backgroundColor:'lightgray', cursor:'unset' }}
                    >
                      Already Submitted
                    </div>
                  </div>
                )}
              </div>
            </AvForm>
          </>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Contact;
