import React, { useEffect, useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Container, Alert, Modal, ModalHeader, ModalBody, } from "reactstrap";
import Feedback from "../Feedback";
import { LoginToken } from "../Utility/common";
import config from "../../config"

const ContactModal = ({isOpen, toggle, cpName}) => {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();
  const [error, setError] = useState("");
  let params = queryString.parse(location.search);
  const loginToken = LoginToken();
  const [data, setData] = useState();
  // console.log(params.PostID);
  useEffect(() => {
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/contact-form`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
      body: JSON.stringify({PostID: params.PostID, UID: params.UID})
     })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        // console.log("data",data);
        setTo(data.results.Email_To);
        setSubject(data.results.Email_Subject);
        setName(data.results.Email_Sender_Name);
        setSenderEmail(data.results.Email_Sender_Email);
      });
  }, []);

  // console.log(data);
  const handleToChange = (e) => {
    setTo(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const [successMessage, setSuccessMessage] = useState();

  const sendEmail = () => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/send-contact-form`,
      { method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({emailTo: to, emailFrom: senderEmail, from: name, subject: subject, message: message})
       }
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setSuccessMessage();
            setError("An error has occured.");
          });
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (data.results.success) {
          // window.location.href = `/`;
          setError("");
          // console.log("success",data.data.results.FormSubmissionResponseMessage);
          setSuccessMessage(data.results.message);
        } else {
          setSuccessMessage();
          setError(data.results.message);
        }
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Modal className="" isOpen={isOpen} toggle={toggle}>
          <ModalHeader>
            Contact {cpName}
          </ModalHeader>
          <ModalBody>
            {successMessage && <Alert color="success">{successMessage}</Alert>}
            {error && <Alert color="danger">{error}</Alert>}
            <>
              <AvForm>
                <div className="campaigns">
                  <div className="campaign" style={{display:'none'}}>
                    <div className="campaign-title">
                      <h5 className="text-pink Archivo">TO</h5>
                    </div>
                    <AvField
                      name="userID"
                      type="text"
                      className="inputField Archivo"
                      errorMessage="Invalid User ID"
                      value={to}
                      disabled={true}
                      style={{ backgroundColor: "#f7f7f7" }}
                      onChange={handleToChange}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                  <div className="campaign" style={{display:'none'}}>
                    <div className="campaign-title">
                      <h5 className="text-pink Archivo">SUBJECT</h5>
                    </div>
                    <AvField
                      name="subject"
                      type="subject"
                      className="inputField Archivo"
                      errorMessage="Invalid Subject"
                      value={subject}
                      onChange={handleSubjectChange}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                  <div className="campaign mt-3">
                    <div className="campaign-title">
                      <h6 className="">Name</h6>
                    </div>
                    <AvField
                      name="name"
                      type="name"
                      className="inputField Archivo"
                      disabled={true}
                      style={{ backgroundColor: "#f7f7f7" }}
                      errorMessage="Invalid Name"
                      value={name}
                      onChange={handleNameChange}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>

                  <div className="campaign mb-3">
                    <div className="campaign-title">
                      <h6 className="">Message</h6>
                    </div>
                    <textarea
                      className="inputField Archivo"
                      name="message"
                      style={{ minWidth: "100%" }}
                      value={message}
                      onChange={handleMessageChange}
                    ></textarea>
                  </div>
                  {!successMessage ? (
                    <div className="campaign">
                      <div
                        className="btn btn-pink gradient-pink full-width float-right"
                        style={{ width: "100%" }}
                        onClick={sendEmail}
                      >
                        Submit
                      </div>
                    </div>
                  ) : (
                    <div className="campaign">
                      <div
                        className="btn btn-light full-width float-right"
                        style={{ width: "100%", }}
                      >
                        Already Submitted
                      </div>
                    </div>
                  )}
                </div>
              </AvForm>
            </>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default ContactModal;
