import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Alert,

} from "reactstrap";
import { Link } from "react-router-dom";
import { } from "react-icons/hi";
import Loader from "react-loader-spinner";
import { get } from "lodash";
import { MdCheckCircle, MdEdit, MdEditDocument, MdEditNote, MdUpdate, MdWarning } from "react-icons/md";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";
import { AvForm, AvField, AvRadioGroup, AvRadio, } from "availity-reactstrap-validation";
import { makeid, TokenAppenditure } from "../Utility/common";
import  config  from "../../config";

const AcceptDecline = (props) => {
  const { data, campaignResponses, loginToken, params, setData, setActiveStep, setSteps, payPalEmail } = props;
  const contractLink = get(data, ["data", "Contract", "Details", "Link"], null);
  const contractChangesMessage = get(data, ["data", "Contract", "Details", "Message"], null);
  const [activeResponse, setActiveResponse] = useState("");
  const [declineModal, setDeclineModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [declineReasonValue, setDeclineReasonValue] = useState();
  const [declineResponseRequired, setDeclineResponseRequired] = useState(false);
  const [requestedChanges, setRequestedChanges] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [file, updateFile] = useState(null);
  const [fileName, updateFileName] = useState("Upload Image");
  const [fileResult, updateFileResult] = useState(null);
  const [declineReason, setDeclineReason] = useState("");
  const [error, setError] = useState();
  const submitContract = () => {
    if (activeResponse === "Decline") {
      toggleDeclineModal();
    } else if (activeResponse === "Accept") {
      setIsLoading(true);
      acceptDecline("Accept")
    } else {
      acceptDecline("Interested");
    }
  }
  function handleImageChange(event) {
    const {
      target: { value, files },
    } = event;
    const fileForUpload = files[0];
    let reader = new FileReader();
    if (
      fileForUpload.type.includes("pdf") ||
      fileForUpload.type.includes("jpeg") ||
      fileForUpload.type.includes("png") ||
      fileForUpload.type.includes("MOV") ||
      fileForUpload.type.includes("MP4") ||
      fileForUpload.type.includes("video")
    ) {
      let url = reader.readAsDataURL(fileForUpload);
      reader.onloadend = function (e) {
        updateFileResult([reader.result]);
      }.bind(this);

      if (fileForUpload) {
        setError("");
        updateFileName(fileForUpload.name.split(".")[0]);
        updateFile(fileForUpload || value);
      } else {
        setError("Must be a valid png or jpg");
      }
    } else {
      setError("Invalid File");
    }
  }

  const handleDeclineReasonChange = (e) => {
    setDeclineReason(e.target.value);
  }
  const handleRequestedChanges = (e) => {
    setRequestedChanges(e.target.value);
  }
  const changeVal = (e) => {
    setDeclineReasonValue(e.target.value);
    if (campaignResponses) {
      campaignResponses.results.data.DeclineOptions.map(e => {
        if (e.DeclineID === parseFloat(declineReasonValue)) {
          if (e.RequiresReason) {
            setDeclineResponseRequired(true);
          } else {
            setDeclineResponseRequired(false);
          }
        }

      });
    }
  }
  const toggleDeclineModal = () => {
    setDeclineModal(!declineModal);
  }

  const toggleAcceptModal = () => {
    setAcceptModal(!acceptModal);
  }

  const responseAction = (varObj) => {
    // console.log(JSON.stringify(varObj));
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/campaignResponse/campaignResponseSave`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify(varObj),
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        setData();
        setSteps();
        setActiveStep();
      });
  }

  const getDeclineDescription = () => {
    let declineDesc = declineReason;
    if (activeResponse === "Decline" || declineModal) {
      if (campaignResponses && !declineDesc) {
        campaignResponses.results.data.DeclineOptions.map(e => {
          if (e.DeclineID === parseFloat(declineReasonValue)) {
            declineDesc = e.Description;
          }
        })
      }
    } else {
      declineDesc = requestedChanges;
    }
    return declineDesc;
  }
  const acceptDecline = (response) => {
    if (response === "Decline") {
      responseAction({
        postId: parseInt(params.PostID),
        uid: params.UID,
        response: response,
        reason: getDeclineDescription(),
        declineId: parseInt(declineReasonValue)
      });
    } else if (response === "Accept" && !file) {
      setIsLoading(true);
      responseAction(
        {
          postId: parseInt(params.PostID),
          uid: params.UID,
          response: response,
        }
      );
    } else if (response === "Interested") {
      responseAction(
        {
          postId: parseInt(params.PostID),
          uid: params.UID,
          response: response,
          reason: requestedChanges
        }
      );
      //accept and need a contract
    } else {
      setIsUploading(true);
      // try {
      //   const new_file = new File([file], makeid(32));
      //   S3FileUpload.uploadFile(new_file, s3Config)
      //     .then(async (data) => {
      //       responseAction(
      //         {
      //           postId: parseInt(params.PostID),
      //           uid: params.UID,
      //           response: response,
      //           link: data.location
      //         }
      //       )
      //     })
      //     .catch((err) => {
      //       // console.log(err);
      //     });
      // } catch (err) {
      //   // console.log("error: ", err);
      // }
    }

  };

  const modalAccept = () => {
    return (
      <Modal isOpen={acceptModal} toggle={toggleAcceptModal}>
        <ModalHeader className="">
          <h5 className="madeGentle mb-0">Confirm Your Payment Information</h5>
          <p className="text-muted mt-0 mb-0 hide-mobile" style={{ fontSize: 13, letterSpacing: 0, fontWeight: 400 }}>Please confirm that the below is your correct PayPal email address</p>
        </ModalHeader>
        <ModalBody style={{ maxHeight: 300, overflowY: "scroll" }}>
          {payPalEmail ? (
            <span>PayPal Email: {payPalEmail}</span>

          ) : (
            <>
              <div className="flex flex-col justify-center text-center py-2">
                <div className="rounded-full" style={{ background: 'rgb(249, 250, 251)', height: 68, width: 68, borderRadius: '100%', display: 'flex', justifyContent: 'center', margin: "AUTO", alignItems: 'center' }}>
                  <MdWarning size={36} color="rgb(239, 68, 68)" />
                </div>
                <h5 className="mt-3">Missing Payment Information</h5>
                <p className="mt-0 font-semibold">
                  Please update your payment information to participate in this campaign.
                </p>
                <Link to="/account/vendor">
                  <button
                    className="radius-round h-11 px-4 py-2 mt-1 btn btn-light"
                    type="primary"
                  >
                    Update PayPal Email
                  </button>
                </Link>
              </div>

            </>
          )}
        </ModalBody>
        {payPalEmail && (
          <ModalFooter>
            <div className="d-flex">
              <Link to="/account/vendor/">

                <Button
                  className="btn btn-light mr-1"

                >
                  <MdEdit className="mr-0" style={{ marginTop: '-2px' }} /> Update
                </Button>
              </Link>
              <Button
                color="pink"
                onClick={() => {
                  acceptDecline("Accept");
                }}
              >
                {isLoading ? (
                  <Loader type="Bars" color="#fff" width={20} height={20} />
                ) :
                  <>
                    <MdCheckCircle className="mr-0" style={{ marginTop: '-2px' }} /> Confirm
                  </>
                }
                
              </Button>
            </div>
          </ModalFooter>
        )}

      </Modal>
    );
  }

  const modalDecline = () => {
    return (
      <Modal isOpen={declineModal} toggle={toggleDeclineModal}>
        <ModalHeader className="madeGentle">Reason For Decline</ModalHeader>
        <ModalBody style={{ maxHeight: 300, overflowY: "scroll" }}>
          <AvForm>
            <div className="campaign-title">
              <h6 className="fw-semi">Why did you decline?</h6>
            </div>
            <AvRadioGroup name="reasonForDecline" label="" onChange={changeVal}>
              {campaignResponses && campaignResponses.results.data.DeclineOptions.map(e => {
                return (<AvRadio label={e.Description} value={e.DeclineID} />);
              })}

            </AvRadioGroup>
          </AvForm>
          {declineReasonValue !== "2" ? (
            <div className="campaign-title"><h6 className="fw-semi">Further Explanation {declineResponseRequired ? "(Required)" : "(Optional)"}</h6>
              <textarea
                className="inputField Archivo"
                name="message"
                style={{ minWidth: "100%" }}
                value={declineReason}
                onChange={handleDeclineReasonChange}
              ></textarea>
            </div>
          ) : (
            <><AvForm><div className="campaign">
              <div className="campaign-title">
                <h6 className=" ">Counter Offer (USD)</h6>
              </div>
              <div>
                <i className="mdi mdi-currency-usd input-icon" ></i>

                <AvField
                  name="counterOffer"
                  type="number"
                  className="inputField form-control"
                  style={{ paddingLeft: 20, maxWidth: 150 }}
                  errorMessage="Invalid Brand Promo Rate"
                  value={declineReason}
                  onChange={handleDeclineReasonChange}
                  validate={{
                    required: { value: true },
                  }}
                />

              </div>
            </div>
            </AvForm>
            </>
          )}

        </ModalBody>
        <ModalFooter>

          <div className="d-flex ">
            <Button
              color="pink"
              className={`${!(!declineResponseRequired || (declineResponseRequired && declineReason)) && 'disabled'} ${!declineReasonValue && 'disabled'} border-none`}
              onClick={() => {
                if (!declineResponseRequired || (declineResponseRequired && declineReason)) {
                  if (declineReasonValue) {
                    acceptDecline("Decline");
                  }
                }
              }}
            >
              Submit
            </Button>
          </div>

        </ModalFooter>
      </Modal>
    );
  }
  if (data) {

    if (data.results.Contract.IsRequired) {
      return (
        <>
          <Card>
            <CardBody>
              <div>
                {contractChangesMessage && (
                  <Alert color="gray" style={{ backgroundColor: '#e9ecf2 !important', }}>{contractChangesMessage}</Alert>
                )}
                <div className="d-flex">
                  <div className="madeGentle d-flex step-circle">1</div>
                  <div className="d-flex flex-col">
                    <p className="fw-bold d-flex ml-1" style={{ marginLeft: 12 }}>Review Contract</p>
                  </div>
                  <div>
                  </div>
                </div>
                <a className="text-white btn btn-pink" target="_blank" href={contractLink}><i className="mdi mdi-download mr-2"></i>Review Contract</a>
              </div>
              <div>
                <div className="d-flex mr-2 mt-4">
                  <div className="madeGentle d-flex step-circle">2</div>
                  <p className="fw-bold d-flex ml-1" style={{ marginLeft: 12 }}>Respond To Offer</p>
                </div>
                <div onClick={() => { setActiveResponse("Accept"); setDeclineReason(""); updateFile(null); }} className={`${activeResponse === "Accept" && "option-active"} p-3 mb-2 option-button`}>
                  <input type="radio" checked={activeResponse === "Accept"} className="text-pink" />
                  <p className="ml-4 radio-label">Accept Current Offer</p>
                </div>
                <div onClick={() => { setActiveResponse("Interested"); setDeclineReason(""); updateFile(null); }} className={`${activeResponse === "Interested" && "option-active"} p-3 mb-2 option-button`}>
                  <input type="radio" checked={activeResponse === "Interested"} className="text-pink" />
                  <p className="ml-4 radio-label">Request Changes</p>
                </div>
                <div className={`p-3 option-button ${activeResponse === "Decline" && "option-active"}`} onClick={() => { setActiveResponse("Decline"); setDeclineReason(""); updateFile(null); }}>
                  <input type="radio" checked={activeResponse === "Decline"} className="text-pink" />
                  <p className="ml-4 radio-label">Decline Offer</p>
                </div>
              </div>
              {activeResponse && (
                <>
                  {activeResponse !== "Decline" && (
                    <div>
                      <div className="d-flex mt-4">
                        <div className="madeGentle d-flex step-circle">3</div>
                        <p className="fw-bold d-flex ml-1" style={{ marginLeft: 12 }}>{activeResponse === "Accept" ? "Upload Signed Contract" : "Request Changes To Contract"}</p>
                      </div>
                      {activeResponse === "Accept" && (
                        <>
                          {file ? (
                            <>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                {file.name}
                                <div className="">
                                  <div
                                    className="full-width float-right mx-1 cursor-pointer" style={{ fontSize: 24 }}
                                    onClick={() => {
                                      updateFile(null);
                                      updateFileResult(null);
                                    }}
                                  >
                                    <i className="mdi mdi-close-circle"></i>
                                  </div>
                                </div>
                              </div>
                              {isUploading && (
                                <span className="text-muted">
                                  Please allow several minutes to upload.
                                </span>
                              )}
                            </>
                          ) : (
                            <>
                              <p className="text-danger mb-0">{error}</p>
                              <label
                                htmlFor="file-upload"
                                className="btn btn-pink"
                              >
                                <i className="mdi mdi-cloud-upload mr-2"></i>Upload {activeResponse === "Accept" ? "Signed" : "Revised"} Contract
                              </label>
                              <input
                                type="file"
                                id="file-upload"
                                accept=".doc, .docx,.txt,.pdf"
                                onChange={handleImageChange}
                                style={{ width: "140px", display: "none" }}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}

              {activeResponse === "Interested" && (
                <>
                  <p className="mt-1 mb-1">Requested Changes</p>
                  <textarea
                    className="inputField Archivo"
                    name="message"
                    style={{ minWidth: "100%", minHeight: 100 }}
                    value={requestedChanges}
                    onChange={handleRequestedChanges}
                  ></textarea>
                </>
              )}

              <div className={`btn btn-pink gradient-pink mt-3 ${((file || getDeclineDescription()) && activeResponse !== "Decline") || (activeResponse === "Decline") ? "" : "disabled"}`} onClick={submitContract}>
                {isLoading ? (
                  <Loader type="Bars" color="#fff" width={20} height={20} />
                ) :
                  "Submit"
                }
              </div>
            </CardBody>
          </Card>
          {modalDecline()}

        </>
      );
    } else {
      return (
        <>
          <div className="acceptDecline">
            <Card
              style={{ borderRadius: 12, cursor: "pointer" }}
              onClick={() => {
                toggleAcceptModal();
              }}
            >
              <CardBody style={{ borderRadius: 12 }} className="p-2">
                <div
                  className="d-flex"
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    key={"active_9"}
                    className="mdi mdi-checkbox-marked-circle text-success"
                    style={{ fontSize: 28 }}
                  />
                  <h5 className="madeGentle">Accept</h5>
                </div>
              </CardBody>
            </Card>
            <Card
              style={{ borderRadius: 12, cursor: "pointer" }}
              onClick={() => {
                toggleDeclineModal();
              }}
            >
              <CardBody style={{ borderRadius: 12 }} className="p-2">
                <div
                  className="d-flex"
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    key={"active_9"}
                    className="mdi mdi-close-box text-danger"
                    style={{ fontSize: 28 }}
                  />
                  <h5 className="madeGentle">Decline</h5>
                </div>
              </CardBody>
            </Card>
          </div>
          {modalDecline()}
          {modalAccept()}
        </>
      );
    }
  }
  return "";

}

export default AcceptDecline;