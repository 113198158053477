import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import Cookies from "js-cookie";
import { Row, Col, Alert, Container ,CardBody,Card} from "reactstrap"
import { useLocation, useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import queryString from "query-string";
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
// actions
import icon from "../../../assets/images/icon.png";
// import images
import logolight from "../../../assets/images/logo-light.png"
import config from "../../../config";
//Import config

const Route = (props) => {
  const [userID, setUserID] = useState();
  const location = useLocation();
  let params = queryString.parse(location.search);
  const history = useHistory();
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleUserIDChange = (e) => {
    setUserID(e.target.value);
  };
  const [password, setPassword] = useState();
   // handleValidSubmit
  const [errorMessage, setErrorMessage] = useState();
  const [error, setError] = useState("");
  const returnUID = () => {
    if (params.UID) {
      return params.UID;
    }else if (params.uid) {
      return params.uid;
    }
    return "";
  }
  useEffect(() => {
    if (params.magic_token && params.magic_token_uid) {
      fetch(
        `https://event_service.genni.com/magic-link/verify?magic_token=${params.magic_token}&magic_token_uid=${params.magic_token_uid}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error('Request failed');
        })
        .then((data) => {
          if (data.success && data.data[0].success) {
            Cookies.set("LoginToken", data.data[0].token);
            history.push(data.data[0].redirect_url);
          } else {
            throw new Error(data.message || 'Verification failed');
          }
        })
        .catch((error) => {
          setErrorMessage("This login link is invalid. Please attempt to login.");
        });
    }
  });

  return (
    <React.Fragment>
      <div
        className="d-flex"
        style={{
          margin: "auto",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          flexDirection: "column",
        }}
      >
        <img
          src={icon}
          alt="iconimg"
          style={{ width: 100, height: 100, margin: "auto" }}
        />
        {!errorMessage ? (
          <Loader
            type="Bars"
            color="#f853bd"
            height={40}
            width={40}
            style={{ margin: "auto", marginTop: "-230px" }}
          />
        ) : (
          <h5
            style={{
              margin: "auto",
              marginTop: "-230px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign:'center'
            }}
          >
            {errorMessage}
            <a href="/login" style={{ margin: "auto", marginTop:8 }}>
              <div className="btn btn-pink">Login</div>
            </a>
          </h5>
        )}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter((Route))

Route.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}