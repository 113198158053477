import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import Cookies from "js-cookie";
import { Row, Col, Alert, Container ,CardBody,Card} from "reactstrap"
import { useHistory, useLocation, } from "react-router-dom";
import queryString from "query-string";
import Loader from "react-loader-spinner";
import { MdCheck, MdCheckBox, MdCheckCircle } from "react-icons/md";
import { Capacitor } from '@capacitor/core';
import { useMailChimp } from 'react-use-mailchimp-signup';
// Redux
import {Stepper, Step, StepLabel,} from "@material-ui/core";
import { connect } from "react-redux"
import { withRouter, Link, } from "react-router-dom";
import logowhite from "../../assets/images/logowhite.png";
import logo from "../../assets/images/Logo.png";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import Personal from './SignUpSteps/Personal';
import "./styles.scss";

import { TokenAppenditure } from '../Utility/common';
import { first } from 'lodash';
import Profiles from './SignUpSteps/Profiles';
import Platforms from './SignUpSteps/Platforms';
import DisputeModal from './DisputeModal';
import config from "../../config";

//Import config
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const SignUp = (props) => {
  const { loading, status, subscribe, message } = useMailChimp({
    action: `https://genni.us18.list-manage.com/subscribe/post?u=c8ddf6f0ec2c9c7a8d76ccbd1&amp;id=5f6c153674&amp;v_id=5453&amp;f_id=008219e7f0`,
  });
  const [userID, setUserID] = useState();

  const [data,setData] = useState("");
  const {setWelcomeState, setCardHeight, setBorderRadius} = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [paypal, setPayPal] = useState("");
  const [gender, setGender] = useState("1");
  const [sexualOrientation, setSexualOrientation] = useState("1");
  const [birthday, setBirthday] = useState(new Date("1/1/1910"));
  const [postalCode, setPostalCode] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [primaryPhone, setPrimaryPhone] = useState();
  const [allowText, setAllowText] = useState(true);
  const [country, setCountry] = useState(1);
  const [disputeModal, setDisputeModal] = useState(false);
  const [stateOther, setStateOther] = useState();
  const [state, setState] = useState(111);
  const [profiles, setProfiles] = useState([{Platform: 'TikTok', Handle:'example', SongRate: "$10.00", BrandRate:"$20.00" }]);
  const [tiktok, setTikTok] = useState("");
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [instagram, setInstagram] = useState("");
  const history = useHistory();
  const [youtube, setYoutube] = useState("");
  const location = useLocation();
  let params = queryString.parse(location.search);
  const [currentStep, setCurrentStep] = useState(0);
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [emailError, setEmailError] = useState();
  const handleUserIDChange = (e) => {
    if (!validateEmail(e.target.value)){
      setEmailError("Invalid Email");
    } else {
      setEmailError("");
    }
    setUserID(e.target.value);
  };

  const submit = () => {
    setIsLoadingBtn(true);
    const newProfiles = profiles && profiles.map((e)=>{
      const platformID = Platforms.filter(a=>{
        if (e.Platform === a.PlatformName) {
          return a.PlatformID;
        }
      });
      const BrandRate = e.BrandRate && e.BrandRate[0] === "$" ? e.BrandRate.substring(1) : e.BrandRate;
      const SongRate = e.SongRate && e.SongRate[0] === "$" ? e.SongRate.substring(1) : e.SongRate;
      return {handle: e.Handle, platformId: platformID[0].PlatformID,brandRate:parseFloat(BrandRate), rate:parseFloat(SongRate)};
    });
    subscribe({email: userID, "First Name": firstName, lastName: lastName});
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/contact/savecreator`,
      {
        method: "POST",
        body: JSON.stringify({
          firstName:firstName,
          lastName:lastName,
          username:userID,
          password:password,
          passwordConfirm:confirmNewPassword,
          email:userID,
          paymentEmail:paypal,
          profileArray:newProfiles,
          addressLine1: address,
          stateId:parseInt(state),
          primaryPhone:primaryPhone,
          mobilePhone:primaryPhone,
          genderIdentityId:parseInt(gender),
          sexualOrientationId:parseInt(sexualOrientation),
          AllowText:Boolean(allowText),
          gender:gender,
          city:city,
          postalCode:postalCode,
          countryId:parseInt(country),
          birthday:`${birthday.getFullYear()}-${birthday.getMonth() + 1}-${birthday.getDate()}`,
          isManager:accountType === "Manager" ? true : false
        }
        ), // <-- Post parameters
        headers: {"Content-Type": "application/json",}

      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        // console.log("data", data);
        if (data.data.success) {
          setCurrentStep(currentStep + 1);
          setData(data.data);
        } else {
          if (data.data.message.includes("already exist")) {
            setDisputeModal(true);
          }
          setError(data.data.message);
        }
        setIsLoadingBtn(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };



  function hasLowerCase(str) {
    return str.toUpperCase() !== str;
  }

  function hasUpperCase(str) {
    return str.toLowerCase() !== str;
  }

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  function hasNumber(myString) {
    return /\d/.test(myString);
  }

  const [passwordErrorMessage, setPasswordErrorMessage] = useState();

  const errorMessageReturn = (nPassword) => {
    if (!hasLowerCase(nPassword)) {
      setPasswordErrorMessage("Must contain a lower case letter");
    } else if (!hasUpperCase(nPassword)) {
      setPasswordErrorMessage("Must contain an upper case letter");
    } else if (!containsSpecialChars(nPassword)) {
      setPasswordErrorMessage("Must contain a special character");
    } else if (!hasNumber(nPassword)) {
      setPasswordErrorMessage("Must contain at least 1 digit");
    } else if (nPassword.length < 8) {
      setPasswordErrorMessage("Must be at least 8 characters");
    } else {
      setPasswordErrorMessage("");
    }
  };

  const validPassword = (nPassword) => {
    if (!hasLowerCase(nPassword)) {
      setIsValidPassword(false);
    } else if (!hasUpperCase(nPassword)) {
      setIsValidPassword(false);
    } else if (!containsSpecialChars(nPassword)) {
      setIsValidPassword(false);
    } else if (!hasNumber(nPassword)) {
      setIsValidPassword(false);
    } else if (nPassword.length < 8) {
      setIsValidPassword(false);
    } else {
      setIsValidPassword(true);
    }
  };

  const handlePasswordChange = (e) => {
    if (confirmNewPassword && confirmNewPassword.length > 0) {
      if (confirmNewPassword !== e.target.value){
        setError("Passwords do not match");
      } else {
        setError("");
      }

    }
    errorMessageReturn(e.target.value);
    validPassword(e.target.value);
    setPassword(e.target.value);
  };
  // console.log(userID);
  const [password, setPassword] = useState();
   // handleValidSubmit
   const handleValidSubmit = (event, values) => {
    // console.log(values)

  }
  const [error, setError] = useState("");
  const [accountType, setAccountType] = useState("Influencer");
  const handleConfirmNewPasswordChange = (e) => {
    if (e.target.value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
    setConfirmNewPassword(e.target.value);
  };

  function validatePhoneNumber(phoneNumber) {
    // Regular expression pattern for North American phone numbers
    const phoneNumberPattern = /^[2-9]\d{2}-\d{3}-\d{4}$/;

    return phoneNumberPattern.test(phoneNumber);
  }

  const isGoodToProceed = () => {
    if (currentStep === 1 && (firstName && lastName) && address && validateEmail(paypal) && city && state && postalCode && primaryPhone !== undefined && primaryPhone && primaryPhone.length > 7 && isValidDate(birthday) && birthday.getTime() !== new Date("1/1/1910").getTime()) {
      return true;
    } else if (currentStep === 2 && profiles.length > 0){
      return true;
    }
    return false;
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleAccountTypeChange = (e) => {
    setAccountType(e.target.value);
  };

  const [successMessage, setSuccessMessage]= useState();


  const returnFormStep = ()=>{
    if (currentStep === 0){
      return (
        <AvForm
          className="form-horizontal full-width container"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <AvRadioGroup
            inline
            name="accountType"
            label="Account Type"
            required
            onChange={handleAccountTypeChange}
            value={accountType}
          >
            <div className='d-flex'>
              <div className={`p-3 option-button ${accountType === "Influencer" && "option-active"}`} onClick={()=>{setAccountType("Influencer");}}>
                <AvRadio label="Influencer" value="Influencer" />
              </div>
              <div className={`p-3 ml-2 option-button ${accountType === "Manager" && "option-active"}`} onClick={()=>{setAccountType("Manager");}}>
                <AvRadio label="Manager" value="Manager" />
              </div>
            </div>
          </AvRadioGroup>
          <div className="mb-3">
            <AvField
              name="userID"
              label="Email"
              type="email"
              value={userID}
              onChange={handleUserIDChange}
              validate={{
                required: { value: true },
                email: true,
              }}
              className="form-control"
              placeholder="Enter Email"
              required
            />

          </div>
          {accountType !== "Manager"  && (
            <>
              <div className="mb-3">
                <div className="float-end"></div>
                <AvField
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  className="inputField"
                  label="Password"
                  errorMessage="Invalid Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                {passwordErrorMessage && (
                  <p
                    className="text-danger"
                    style={{ fontSize: "13px", marginTop: "-16px" }}
                  >
                    {passwordErrorMessage}
                  </p>
                )}
              </div>
              <div className="mb-3">
                <div className="float-end"></div>
                <AvField
                  name="confirmPassword"
                  type="password"
                  placeholder="Enter Password"
                  className="inputField"
                  label="Confirm Password"
                  errorMessage="Invalid Password"
                  value={confirmNewPassword}
                  onChange={handleConfirmNewPasswordChange}
                />
              </div>
            </>
          )}

          <div className="mt-3">
            <p className="text-danger">{error}</p>
            <div>
              {accountType === "Manager" ? (
                <>
                  <button
                    className={`btn gradient-pink btn-pink text-white w-100 waves-effect waves-light float-right ${
                      validateEmail(userID)
                        ? ""
                        : "disabled"
                    }`}
                    style={{ float: "right" }}
                    onClick={() => {
                      if (
                        validateEmail(userID)
                      ) {
                        fetch(
                          `${config.genni.marketing_portal.API_KEY}/creatorapi/check-username?username=${userID}`,
                          { method: "GET" }
                        )
                          .then((res) => {
                            if (res.ok) {
                              return res.json();
                            }
                            throw res;
                            //const jsonRes = JSON.parse(res);
                          })
                          .then(async (data) => {
                            if (data.results.success) {
                              setError("");
                              fetch("https://mandrillapp.com/api/1.0/messages/send.json", {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                  key: "md-fhneX5PkBIP4iGyWiZ_BeQ",
                                  message: {
                                    from_email: "info@genni.com",
                                    to: [{ email: "outreach@genni.com" }, { email: "tech@genni.com" }],
                                    subject: "Genni Manager Request",
                                    html: `The following manager has requested access to genni: ${userID}`,
                                  },
                                }),
                              })
                                .then(function (response) {
                                  return response.json();
                                })
                                .then(function (data) {
                                  // console.log(data);
                                })
                                .catch(function (error) {
                                  // console.log(error);
                                });
                              setSuccessMessage("Thanks for requesting access to become a Genni manager! A member of our team will reach out to you shortly to begin the onboarding process.")
                              setCurrentStep(3);
                            } else {
                              setError("Email already exists");

                            }

                          })
                          .catch((error) => {
                            setError("Email already exists");
                          });
                      }
                    }}
                  >
                    Request Access
                  </button>
                </>
              ) : (
                <button
                  className={`btn gradient-pink btn-pink text-white w-100 waves-effect waves-light float-right
                  ${
                    !passwordErrorMessage &&
                    password === confirmNewPassword && password &&
                    validateEmail(userID)
                      ? ""
                      : "disabled"
                  }`}
                  style={{ maxWidth: 100, float: "right" }}
                  onClick={() => {
                    if (
                      !passwordErrorMessage &&
                      password === confirmNewPassword &&
                      validateEmail(userID)
                    ) {
                      fetch(
                        `${config.genni.marketing_portal.API_KEY}/creatorapi/check-username?username=${userID}`,
                        { method: "GET",

                         }
                      )
                        .then((res) => {
                          if (res.ok) {
                            return res.json();
                          }
                          throw res;
                          //const jsonRes = JSON.parse(res);
                        })
                        .then(async (data) => {
                          if (data.results.success) {
                            setError("");
                            setCurrentStep(currentStep + 1);
                          } else {
                            setError("Email already exists");

                          }

                        })
                        .catch((error) => {
                          setError("Email already exists");
                        });
                    }
                  }}
                >
                  Next
                </button>
              )}

            </div>
          </div>
        </AvForm>
      );
    } else if (currentStep === 1){
      return (
        <>
          <Personal
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            gender={gender}
            paypal={paypal}
            setPayPal={setPayPal}
            setGender={setGender}
            sexualOrientation={sexualOrientation}
            setSexualOrientation={setSexualOrientation}
            birthday={birthday}
            setBirthday={setBirthday}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            address={address}
            setAddress={setAddress}
            city={city}
            setCity={setCity}
            primaryPhone={primaryPhone}
            setPrimaryPhone={setPrimaryPhone}
            allowText={allowText}
            setAllowText={setAllowText}
            country={country}
            setCountry={setCountry}
            state={state}
            setState={setState}
            stateOther={stateOther}
            setStateOther={setStateOther}
          />
          <div className="mt-3 full-width">
            <p className="text-danger">{error}</p>
            <div>
              {currentStep > 0 && (
                <button
                  className={`btn btn-secondary fw-semi text-white w-100 waves-effect waves-light float-right`}
                  style={{ maxWidth: 100, float: "left", height: 43 }}
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  Back
                </button>
              )}
              <button
                className={`btn gradient-pink btn-pink text-white w-100 waves-effect waves-light float-right ${
                  isGoodToProceed()
                    ? ""
                    : "disabled"
                }`}
                style={{ maxWidth: 100, float: "right" }}
                onClick={() => {
                  if (
                    isGoodToProceed()
                  ) {
                    setCurrentStep(currentStep + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
          <div className='mt-4'></div>
        </>
      );
    } else if (currentStep === 2){
      return (
        <>
          {error && (<Alert color="danger">{error}</Alert>)}
          <Profiles
            profiles={profiles}
            setProfiles={setProfiles}
          />
          <div className="mt-3 full-width">
            <p className="text-danger">{error}</p>
            <div>
              {currentStep > 0 && (
                <button
                  className={`btn btn-secondary fw-semi text-white w-100 waves-effect waves-light float-right`}
                  style={{ maxWidth: 100, float: "left",height: 43 }}
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  Back
                </button>
              )}
              <button
                className={`btn gradient-pink btn-pink text-white w-100 waves-effect waves-light float-right ${
                  isGoodToProceed()
                    ? ""
                    : "disabled"
                }`}
                style={{ maxWidth: 100, float: "right" }}
                onClick={() => {
                  if (
                    isGoodToProceed()
                  ) {
                    submit();
                  }
                }}
              >
                {isLoadingBtn ? (
                  <Loader type="Bars" color="#fff" width={20} height={20} />
                ) :
                  "Submit"
                }
              </button>
            </div>
          </div>
          <div className='mt-4'></div>
        </>
      );
    }else if (currentStep === 3){
      return (
        <div className="flex flex-col justify-center text-center py-2">
          <div className="rounded-full" style={{ background: 'rgb(249, 250, 251)', height: 68, width: 68, borderRadius: '100%', display: 'flex', justifyContent: 'center', margin: "AUTO", alignItems: 'center' }}>
            <MdCheckCircle size={36} color="#32a852" />
          </div>
          <h4 className="mt-3">Successful Sign Up!</h4>
          <p className="mt-0 font-semibold" style={{ fontSize: '1rem' }}>
            {successMessage ? successMessage : (
              `Please check your email to confirm your account.`
            )}
          </p>
        </div>

      );
    }
  }

  useEffect(() => {
    document.body.className = "authentication-bg";
    if (params.token){
      fetch(
        `${config.genni.marketing_portal.API_KEY}/creatorapi/check`,
        { method: "GET",
        headers: { "Content-Type": "application/json", "x-api-key":params.token || Cookies.get("LoginToken") },

         }
      )
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          }else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setError(JSON.parse(errText.message).messages[0]);
          });
        }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then(async (data) => {
          // console.log("data",data);
          if (data.results.success) {
            await Cookies.set("LoginToken", params.token);
            // console.log(params.token);
            history.push(`/dashboard${TokenAppenditure()}`);
          }
        });

    }
    return function cleanup() {
      document.body.className = "";
    };
  });

  const steps = [
    "Account",
    "Personal",
    "Finish",
  ];

  return (
    <React.Fragment>

      <div className={`text-center mt-3 ${Capacitor.isNativePlatform() && "pt-4"}`}>
        <h3 className="madeGentle">Sign Up</h3>
        {(currentStep !== 3 || Capacitor.isNativePlatform()) && (
          <p className="text-muted">Already have an account?
          {Capacitor.isNativePlatform() ? (
            <Link
              onClick={()=>{setWelcomeState("login");setCardHeight("60vh"); setBorderRadius(60);}}
              className="fw-medium text-pink"
            >
            {" "}
            Log In{" "}
          </Link>
          ) : (
            <Link
              to="/login"
              className="fw-medium text-pink"
            >
              {" "}
              Log In{" "}
            </Link>
          )}
          </p>
        )}

      </div>

      <Stepper activeStep={currentStep} alternativeLabel className="full-width">
        <Step key="Account">
          <StepLabel>Account</StepLabel>
        </Step>
        <Step key="Personal">
          <StepLabel>Personal</StepLabel>
        </Step>
        <Step key="Profiles">
          <StepLabel>Profiles</StepLabel>
        </Step>
        <Step key="Finish">
          <StepLabel>Finish</StepLabel>
        </Step>
      </Stepper>
      <div className={ `container mt-0 px-3 ${Capacitor.isNativePlatform() && 'pb-100'}`}>
        {returnFormStep()}
      </div>
      <div className="mt-4 text-center">

    </div>
          <DisputeModal email={userID} setEmail={setUserID} message={error} modal={disputeModal} setModal={setDisputeModal} />
    </React.Fragment>
  );
}


export default withRouter((SignUp))
