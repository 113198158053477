import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as moment from "moment";
import Cookies from "js-cookie";
import { Container, Row, Col, BreadcrumbItem } from "reactstrap";
import { Capacitor } from "@capacitor/core";
import { CommonLoader, LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";
import config from "../../config";

const FAQ = () => {
  const location = useLocation();

  const campaignType = location.pathname.slice(10);
  let params = queryString.parse(location.search);
  const loginToken = LoginToken();
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [data, setData] = useState();

  useEffect(() => {
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/faq?faqId=${params.FaqID}`, {
      method: "GET",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (!data.error) {
          setData(data);
        } else {
          // window.location.href = "/login";
        }
      });
  }, []);

  // console.log(data);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {Breadcrumbs(data && data.results.FaqID, "FAQ", `/faq${TokenAppenditure()}`)}

              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 madeGentle">
                  {data && data.results.Question}
                </h4>
              </div>
              {data ? (
                <div className="campaigns">
                  <div
                    className="color-white"
                    style={{
                      fontFamily: "Arial",
                      fontSize: 16,
                    }}
                    dangerouslySetInnerHTML={{ __html: data.results.Answer }}
                  ></div>
                </div>
              ) : (
                <CommonLoader />
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};;

export default FAQ;
