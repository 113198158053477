const config = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  genni: {
    marketing_portal: {
      API_KEY: process.env.REACT_APP_API_URL,
    }
  },
};

export default config;
