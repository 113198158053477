import React, { useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import config from "../../config";

const Photo = () => {
  const history = useHistory();
  const [error, setError] = useState('');
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const loginToken = LoginToken();

  const validateFile = (file) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const maxSize = 100 * 1024 * 1024; // 100MB

    if (!allowedTypes.includes(file.type)) {
      setError('Please upload a valid JPG or PNG file');
      return false;
    }

    if (file.size > maxSize) {
      setError('File size exceeds 100MB limit');
      return false;
    }

    return true;
  };

  const handleImageChange = (event) => {
    const fileList = event.target.files;
    if (!fileList || fileList.length === 0) return;

    const selectedFile = fileList[0];
    if (validateFile(selectedFile)) {
      setError('');
      setFile(selectedFile);
    }
  };

  const uploadFile = async () => {
    if (!file) return;

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/photo`, {
        method: 'POST',
        headers: {
          'x-api-key': loginToken
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const result = await response.json();

      if (result.results.success) {
        history.push(`/settings${TokenAppenditure()}`);
      } else {
        setError(result.message || 'Upload failed');
      }
    } catch (err) {
      setError('An error occurred while uploading the file');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {Breadcrumbs("Choose Profile Photo", "Account Settings", `/settings${TokenAppenditure()}`, true)}

              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 madeGentle">Choose Profile Photo</h4>
              </div>

              {error && <Alert color="danger">{error}</Alert>}

              <div className="card p-3">
                <AvForm>
                  <div className="campaigns">
                    <div className="campaign">
                      <div className="campaign-title">
                        <h5 className="fw-bold">SELECT PHOTO (JPG or PNG FILE)</h5>
                      </div>
                    </div>
                    <div className="campaign">
                      {file ? (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          {file.name}
                          <div className="mt-2">
                            <div
                              className="btn btn-pink float-right"
                              onClick={uploadFile}
                            >
                              {isLoading ? (
                                <Loader type="Bars" color="#fff" width={20} height={20} />
                              ) : (
                                "Upload Photo"
                              )}
                            </div>
                            <div
                              className="btn btn-secondary float-right mx-1"
                              onClick={() => {
                                setFile(null);
                                setError('');
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <label
                            htmlFor="file-upload"
                            className="full-width float-right"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "flex",
                              flexDirection: 'column',
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: 'pointer',
                              border: "2px dashed #575767",
                              padding: 30,
                            }}
                          >
                            <i className="mdi mdi-cloud-upload text-pink" style={{ fontSize: 48 }}></i>
                            <h4>Upload File</h4>
                            <span>JPG or PNG</span>
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            id="file-upload"
                            onChange={handleImageChange}
                            style={{ width: "140px", display: "none" }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </AvForm>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Photo;
