import React, {useEffect, useState} from "react";
import {
  Row,
  Col,
} from "reactstrap";
import { Breadcrumbs, LoginToken, TokenAppenditure } from "../Utility/common";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import {MdOutlineChevronRight} from "react-icons/md"
import { Capacitor } from "@capacitor/core";
import config from "../../config";

const Account = () => {
  const [data, setData] = useState();
  const loginToken = LoginToken(); // => 'value'
  useEffect(() => {
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/switch`, {
      method: "GET",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        // console.log(data);
        setData(data);
      });
  }, []);
  const cardsArr = [
    {
      name: "Login Information",
      url: `/account/credentials/${TokenAppenditure()}`,
      subtext: "Update your login information",
    },
    {
      name: "Contact Information",
      url: `/account/contact/${TokenAppenditure()}`,
      subtext: "Update your contact info",
    },
    {
      name: "Payment Information",
      url: `/account/vendor/${TokenAppenditure()}`,
      subtext: "Update your payment info",
    },
    {
      name: "Tax Documents",
      url: `/account/tax/${TokenAppenditure()}`,
      subtext: "Manage your tax documents",
    },

    {
    name: "Social Media Profiles",
    url: `/account/social/${TokenAppenditure()}`,
    subtext: "Manage your social media profiles",
    },
    {
      name: "Profile Photo",
      url: `/account/photo/${TokenAppenditure()}`,
      subtext: "Upload your photo",
    },

    {
      name: data && data.contactDetail.contactType === "manager" ? "Manage Roster" : "Genni Accounts",
      url: `/account/accounts/${TokenAppenditure()}`,
      subtext: data && data.contactDetail.contactType === "manager" ? "Manage Your Creators" : "Manage Your Linked Accounts",
    },
    {
      name: "Deactivate Account",
      url: `/account/deactivate/${TokenAppenditure()}`,
      subtext: "Deactivate Your Account",
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {Breadcrumbs("Account Settings", "Home", `/dashboard${TokenAppenditure()}`)}
              <div className="page-title-box d-flex align-items-center justify-content-between">
                {!Capacitor.isNativePlatform() && (<h5 className="mb-0 madeGentle">Account Settings</h5>)}
              </div>
              <div className="card shadow br-8 bg-white full-width accountSettingsCard">
                {data ? (
                  <>
                    {cardsArr.map((e) => {
                      if ((e.name === "Social Media Profiles" && ((data && data.contactDetail.contactType === "manager") || !data))) {
                        return "";
                      } else {
                        return (
                          <Link to={e.url}>
                            <div className="row-table pb-3 pt-3 px-3 border-bottom cursor-pointer d-flex flex-row align-items-center">
                              <div className="row-group">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <h6
                                    className=" mb-0"
                                    style={{ fontSize: ".93rem" }}
                                  >
                                    {e.name}
                                  </h6>
                                  <p
                                    className="text-muted mb-0 Archivo"
                                    style={{
                                      textTransform: "capitalize",
                                      fontSize: 12,
                                    }}
                                  >
                                    {e.subtext}
                                  </p>
                                </div>
                              </div>
                              <MdOutlineChevronRight size={24} color="#162638" />

                            </div>
                          </Link>
                        );
                      }
                    })}
                  </>
                ) : (
                  <>
                    {cardsArr.map((e) => {

                        return (
                          <Link to={e.url}>
                            <div className="row-table pb-3 pt-3 px-3 border-bottom cursor-pointer d-flex flex-row align-items-center">
                              <div className="row-group">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Skeleton
                                    widthRandomness={10}
                                    width="110px"
                                    height="16px"
                                    baseColor="#e6ebf2"
                                    className="mt-0"
                                    highlightColor="#f0f4f7"
                                  />
                                  <Skeleton
                                    widthRandomness={10}
                                    width="160px"
                                    height="14px"
                                    baseColor="#e6ebf2"
                                    className="mt-0 mb-0"
                                    highlightColor="#f0f4f7"
                                  />
                                </div>
                              </div>
                              <MdOutlineChevronRight size={24} color="#162638" />

                            </div>
                          </Link>
                        );

                    })}
                  </>
                )}

              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};;

export default Account;