import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { LoginToken, TokenAppenditure } from "../Utility/common";
import queryString from "query-string";
import { useLocation, } from "react-router-dom";
import { get } from "lodash";
import {
    Row,
    Col,
    Alert,
    BreadcrumbItem,
} from "reactstrap";
import config from "../../config"


function range(start, thisYear) {
    const arr = [];
    for (let index = start; index < thisYear; index++) {
        arr.push(index);
    }
    return arr;
}

const EditPlatformModal = ({ isOpen, toggle, selectedHandle, setSelectedHandle, setRefetch, refetch, platforms, setSuccessMessage }) => {
    const [step, setStep] = useState(0);
    const location = useLocation();
    let params = queryString.parse(location.search);
    const [errorMessage, setErrorMessage] = useState();
    const [platformID, setPlatformID] = useState(selectedHandle.PlatformID);
    const [handle, setHandle] = useState(selectedHandle.Handle);
    const [brandRate, setBrandRate] = useState(selectedHandle.Accounting_PostNegotiatedBrandRate);
    const [songRate, setSongRate] = useState(selectedHandle.Accounting_PostNegotiatedRate);
    const handleBrandRateChange = (e) => {
        setBrandRate(e.target.value);
    };

    const handleSongRateChange = (e) => {
        setSongRate(e.target.value);
    };

    const handleHandleChange = (e) => {
        setHandle(e.target.value ? e.target.value : "@");
    };

    const changePlatform = (e) => {
        setPlatformID(e.target.value);
    };
    const loginToken = LoginToken();

    const deleteContactPlatform = () => {
        fetch(
            `${config.genni.marketing_portal.API_KEY}/creatorapi/admin-profile-delete?x-api-key=${loginToken}`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ContactID: parseInt(params.ContactID),
                    UID: selectedHandle.UID,
                    ContactPlatformID: selectedHandle && selectedHandle.ContactPlatformID,
                })
            }
        )
            .then(async (res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    await res.text().then((text) => {
                        const errText = new Error(text);
                        setSuccessMessage();
                        setErrorMessage(JSON.parse(text).errorReason.response.errors[0]);
                    });
                }
                throw res;
                //const jsonRes = JSON.parse(res);
            })
            .then((d) => {
                setRefetch(refetch + 1);
                setSuccessMessage("Handle successfully deleted.")
                setHandle(null);
                toggle();
            });
    }
    // console.log(state);
    const submitPlatformUpdate = () => {
        if (handle.length > 1) {
            // console.log(city, reportedState, oldPrimaryEmail, reportedCountry)
            fetch(
                `${config.genni.marketing_portal.API_KEY}/creatorapi/admin-profile-save?x-api-key=${loginToken}`,
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ContactID: parseInt(params.ContactID),
                        UID: selectedHandle.UID,
                        ContactPlatformID: selectedHandle && selectedHandle.ContactPlatformID,
                        Handle: handle,
                        PlatformID: parseInt(platformID),
                        Rate: parseFloat(songRate),
                        BrandRate: parseFloat(brandRate),
                        Followers: 1,
                    })
                }
            )
                .then(async (res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        await res.text().then((text) => {
                            const errText = new Error(text);
                            setErrorMessage(JSON.parse(text).errorReason.response.errors[0]);
                            setSuccessMessage();
                        });
                    }
                    throw res;
                    //const jsonRes = JSON.parse(res);
                })
                .then((d) => {
                    setRefetch(refetch + 1);
                    setSuccessMessage("Handle successfully updated.");
                    setHandle(null);
                    toggle();
                });
        }

    }


    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader className="">
                Edit Platform
            </ModalHeader>
            <ModalBody
                style={{
                    overflowY: "scroll",
                }}
            >
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                {step === 0 && (
                    <AvForm>
                        <div className="campaigns bg-white p-3 card">
                            <div className="campaign mb-2">
                                <div className="campaign-title">
                                    <h6 className="">Platform</h6>
                                </div>
                                <select
                                    className="inputField custom-select"
                                    id="Sortbylist-job"
                                    value={platformID}
                                    onChange={changePlatform}
                                >
                                    {platforms &&
                                        platforms.map((e, i) => {
                                            return (
                                                <option key={i} value={e.PlatformID}>
                                                    {e.PlatformName}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            <div className="campaign mt-2">
                                <div className="campaign-title">
                                    <h6 className="">Handle</h6>
                                </div>
                                <AvField
                                    name="handle"
                                    type="text"
                                    className="inputField"
                                    errorMessage="Invalid Handle"
                                    value={`${handle && handle[0] !== "@" ? "@" : ""}${handle}`}
                                    disabled
                                    onChange={handleHandleChange}
                                    validate={{
                                        required: { value: true },
                                    }}
                                />
                            </div>
                            <div className="campaign">
                                <div className="campaign-title">
                                    <h6 className=" ">Song Rate (USD)</h6>
                                </div>
                                <div>
                                    <i className="mdi mdi-currency-usd input-icon" ></i>
                                    <AvField
                                        name="songRate"
                                        type="number"
                                        className="inputField"
                                        errorMessage="Invalid Song Promo Rate"
                                        style={{ paddingLeft: 20 }}
                                        value={songRate && songRate.includes("$") ? parseInt(songRate.slice(1)) : parseInt(songRate)}
                                        onChange={handleSongRateChange}
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="campaign">
                                <div className="campaign-title">
                                    <h6 className=" ">Brand Rate (USD) </h6>
                                </div>
                                <div>
                                    <i className="mdi mdi-currency-usd input-icon" ></i>
                                    <AvField
                                        name="brandRate"
                                        type="number"
                                        className="inputField form-control"
                                        style={{ paddingLeft: 20 }}
                                        errorMessage="Invalid Brand Promo Rate"
                                        value={brandRate && brandRate.includes("$") ? parseInt(brandRate.slice(1)) : parseInt(brandRate)}
                                        onChange={handleBrandRateChange}
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </div>
                            </div>


                        </div>
                    </AvForm>
                )}
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-light" onClick={deleteContactPlatform}>
                    Delete
                </Button>
                <Button color="pink" onClick={submitPlatformUpdate} disabled={handle && handle.length <= 1}>
                    Update
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default EditPlatformModal;