import React, { useEffect, useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {Capacitor} from "@capacitor/core";
import { Row, Col, BreadcrumbItem, Button, Alert } from "reactstrap";
import { LoginToken, TokenAppenditure } from "../Utility/common";
import { TbAlertCircleFilled } from "react-icons/tb";
import config from "../../config"

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const Password = () => {
  const [existingPassword, setExistingPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState();
  const [successMessageEmail, setSuccessMessageEmail] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [loginSuccessMessage, setLoginSuccessMessage] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const location = useLocation();
  const loginToken = LoginToken(); // => 'value'
  const handleExistingPasswordChange = (e) => {
    setExistingPassword(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    errorMessageReturn(e.target.value);
    validPassword(e.target.value);
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    if (e.target.value !== newPassword) {
      setErrorMessage("Passwords do not match");
    } else {
      setErrorMessage("");
    }
    setConfirmNewPassword(e.target.value);
  };

  function determineIfGood() {
    // console.log(isValidPassword);
    if (isValidPassword) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/username-detail?x-api-key=${loginToken}`,
      { method: "GET" }
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        // console.log("d",d);
        setEmail(d.results.Details.Fields[0].Value);
      });
  }, [])


  const updatePassword = () => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/change-password`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({originalPassword: existingPassword,newPassword: newPassword})
      }
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {

        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        // console.log("d",d);
        if (d.results.success) {
          setSuccessMessage(d.results.message);
          setErrorMessage("");
        } else {
          setErrorMessage(d.results.message);
          setSuccessMessage("");
        }

      });
  };

  const updateEmail = () => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/update-username`,
      { method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({username: email})
       }
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {

        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        if (d.results.success) {
          setLoginSuccessMessage(d.results.message);
          // setSuccessMessageEmail(d.results);

        } else {
          setLoginErrorMessage(d.results.message);
        }
      });
  };
  function hasLowerCase(str) {
    return str.toUpperCase() !== str;
  }

  function hasUpperCase(str) {
    return str.toLowerCase() !== str;
  }

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  function hasNumber(myString) {
    return /\d/.test(myString);
  }

  const validPassword = (nPassword) => {
    if (!hasLowerCase(nPassword)) {
      setIsValidPassword(false);
    } else if (!hasUpperCase(nPassword)) {
      setIsValidPassword(false);
    } else if (!containsSpecialChars(nPassword)) {
      setIsValidPassword(false);
    } else if (!hasNumber(nPassword)) {
      setIsValidPassword(false);
    } else if (nPassword.length < 8) {
      setIsValidPassword(false);
    } else {
      setIsValidPassword(true);
    }
  }

  const errorMessageReturn = (nPassword) => {
    if (!hasLowerCase(nPassword)) {
       setPasswordErrorMessage("Must contain a lower case letter");
    } else if (!hasUpperCase(nPassword)) {
      setPasswordErrorMessage("Must contain an upper case letter");
    }else if (!containsSpecialChars(nPassword)) {
      setPasswordErrorMessage("Must contain a special character");
    }else if (!hasNumber(nPassword)) {
      setPasswordErrorMessage("Must contain at least 1 digit");
    }else if (nPassword.length < 8) {
      setPasswordErrorMessage("Must be at least 8 characters");
    } else {
      setPasswordErrorMessage("");
    }
  }

  // console.log(determineIfGood());

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
              {!Capacitor.isNativePlatform() && (
                <ol className="breadcrumb mt-0 mb-3">
                  <BreadcrumbItem>
                    <a href={`/settings${TokenAppenditure()}`}>Account Settings</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <a>Login Information</a>
                  </BreadcrumbItem>
                </ol>
              )}


              <div className="card p-3">
                {loginErrorMessage && (
                  <Alert color="danger">{loginErrorMessage}</Alert>
                )}
                {loginSuccessMessage && (
                  <Alert color="success"><i className="mdi mdi-check-circle"></i>{loginSuccessMessage}</Alert>
                )}
                <div className="page-title-box d-flex align-items-center justify-content-between">
                <h5 className="mb-3 madeGentle">Change Login Email</h5>
              </div>
                <AvForm>
                  <div className="campaigns">
                    <div className="campaign mb-3">

                      <div className="campaign-title ">
                        <h6 className="">Login Email</h6>
                      </div>
                      <AvField
                        name="email"
                        value={email}
                        onChange={handleEmailChange}
                        type="email"
                        className="inputField"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>

                    <div className="campaign">
                      <p className="text-danger">{errorMessageEmail}</p>
                      <p className="text-success">{successMessageEmail}</p>
                      {!successMessageEmail && (
                        <button
                          className={`btn btn-pink gradient-pink full-width float-right ${
                            validateEmail(email) ? "" : "disabled"
                          }`}
                          style={{
                            width: "100%",
                            border: "none",
                          }}
                          onClick={updateEmail}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </AvForm>
              </div>


              <div className="card p-3">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h5 className=" madeGentle mb-3">Change Password</h5>
                </div>
                <AvForm>
                  <div className="campaigns">
                    <div className="campaign mb-3">
                      <div className="campaign-title ">
                        <h6 className=" ">Existing Password</h6>
                      </div>
                      <AvField
                        name="existingPassword"
                        value={existingPassword}
                        onChange={handleExistingPasswordChange}
                        type="password"
                        className="inputField"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                    <div className="campaign mb-3">
                      <div className="campaign-title">
                        <h6 className=" ">New Password</h6>
                      </div>
                      <AvField
                        name="newPassword"
                        type="password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        className="inputField"
                        invalid={!isValidPassword}
                      />
                      <p
                        className="text-danger"
                        style={{ fontSize: "13px", marginTop: "-16px" }}
                      >
                        {passwordErrorMessage}
                      </p>
                    </div>
                    <div className="campaign mb-3 pt-3">
                      <div className="campaign-title">
                        <h6 className=" ">Confirm New Password</h6>
                      </div>
                      <AvField
                        name="confirmPassword"
                        value={confirmNewPassword}
                        onChange={handleConfirmNewPasswordChange}
                        type="password"
                        className="inputField"
                        errorMessage="Invalid Password"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                    <div className="campaign">
                      <p className="text-danger">{errorMessage}</p>
                      <p className="text-success">{successMessage}</p>
                      <button
                        className={`btn btn-pink gradient-pink full-width float-right ${
                          isValidPassword &&
                          existingPassword.length > 0 &&
                          newPassword === confirmNewPassword
                            ? ""
                            : "disabled"
                        }`}
                        style={{
                          width: "100%",
                          border: "none",
                        }}
                        onClick={updatePassword}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </AvForm>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};;

export default Password;
