import {useState, useEffect} from "react";
import { CommonLoader, NoResults, LoginToken,  } from "../Utility/common";
import ContestPaymentTable from "../Utility/contestpaymenttable";
import {  useHistory } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import moment from "moment";
import "moment-timezone";
import { get } from "lodash";
import { listPayments } from "../../graphql/queries";
import { API } from "aws-amplify";
import React from "react";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import config from "../../config";

const calcDateValue = (d) => {
  const t = new Date("January 1 1970 00:00:00-0:00");
  t.setSeconds(t.getSeconds() + parseFloat(d));
  const momentT = moment(t);
  return momentT.tz(moment.tz.guess()).format("MMM D hh:mmA z");
};

const ContestPayments = ({gridType, data, paymentType}) => {
    const history = useHistory();
    const loginToken = LoginToken();
    const [isDone, setIsDone] = useState(false);
    const [payments, setPayments] = useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const fetchPayments = async (email) => {
        const myPayments = [];
        let done = false;
        let nToken = false;
        while (!done) {
          const apiData = await API.graphql({
            query: listPayments,
            variables: {
              limit: 1000,
              nextToken: !nToken ? null : nToken,
            },
          });
          const myPaymentsTemp = get(
            apiData,
            ["data", "listPayments", "items"],
            []
          );
          myPaymentsTemp.map((e) => {
            if (e._deleted !== true) {
              myPayments.push(e);
            }
          });

          nToken = apiData.data.listPayments.nextToken;
          if (nToken === null) {
            done = true;
          }
        }
        if (done && nToken === null && email) {
          let tempPayments = [];
        //   // console.log("my posts", myPayments);
          myPayments.map((e) => {
            if (
              !e._deleted &&
              e.ContactEmail === email
            ) {
              tempPayments.push(e);
            }
          });
          setIsDone(true);
          setTotalPages(tempPayments.length % 10);
          setPayments(tempPayments.filter(pay => pay.Status.includes(paymentType.label)));
        } else {
          setIsDone(true);
        }
      };
      useEffect(() => {
        setPayments();

        fetch(
          `${config.genni.marketing_portal.API_KEY}/creatorapi/information`,
          { method: "GET",
            headers: { "Content-Type": "application/json", "x-api-key": loginToken },
           },
        )
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw res;
            //const jsonRes = JSON.parse(res);
          })
          .then((data) => {
              fetchPayments(data.results.ContactInformation.logInUsername);

          });
      }, [paymentType]);
    return (
        <>
        {gridType === "grid" ? (
            <div className="campaigns mt-2">
            {payments ? (
              <div className="campaigns">
                {payments.length > 0 ? (
                  payments.slice(
                    (currentPage -1) * 10,
                    currentPage * 10 + 10
                  ).map((e, i) => {
                    return (
                      <div
                        key={i}
                        className="campaign card p-3 mb-2 mt-0"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push(`/contestdetail?PostID=${e.CompetitionID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`);
                        }}
                      >
                        <div
                          className="campaign-title border-bottom border-ppink pb-2 mb-2"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <h5 className="fw-bold mb-0 paymentsTitle" >
                            <span>${e.Amount}</span>{" "}
                          </h5>
                        </div>
                        <div className="campaign-details mt-1 mb-0">
                          <div className="campaign-detail">
                            <p className="fw-bold mb-0 ">Competition ID: <span className="fw-normal">{e.CompetitionID}</span></p>
                          </div>
                        </div>
                        <div className="campaign-details mt-1 mb-0">
                          <div className="campaign-detail">
                            <p className="fw-bold mb-0 ">Last Updated: <span className="fw-normal">{new Date(e.updatedAt).toDateString()}</span></p>
                          </div>
                        </div>
                        <div className="campaign-details mt-1 mb-0">
                          <div className="campaign-detail">
                            <p className="fw-bold mb-0 ">Payment Email: <span className="fw-normal">{e.PaymentEmail}</span></p>
                          </div>
                        </div>
                        <div className="campaign-details mt-1 mb-0">
                          <div className="campaign-detail">
                            <p className="fw-bold mb-0 ">Status: <span className="fw-normal">{e.Status}</span></p>
                          </div>
                        </div>

                      </div>
                    );
                  })
                ) : (
                  <NoResults className="mt-4" />
                )}
              </div>
            ) : (
              <CommonLoader />
            )}
            {payments && payments.length > 10 && (
              <div className="card mt-2" style={{width:'100%'}}>
                <div
                  className="px-4"
                  style={{
                    textAlign: "right",
                    marginTop: 16,
                    paddingBottom: 0,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <p className="px-4" style={{ fontSize: 14 }}>
                    Page {currentPage} of {totalPages}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: 27,
                      marginTop: -10,
                      width: 72,
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className={`pagination-btn ${
                        currentPage > 1
                          ? ""
                          : "disabled-pagination-btn text-muted"
                      }`}
                      onClick={() => {
                        if (currentPage > 1) {
                          setCurrentPage(parseInt(currentPage) - 1);
                        }
                      }}
                    >
                      {" "}
                      <MdOutlineChevronLeft />
                    </div>
                    <div
                      className={`pagination-btn ${
                        currentPage < totalPages
                          ? ""
                          : "disabled-pagination-btn text-muted"
                      }`}
                      onClick={() => {
                        if (currentPage < totalPages) {
                          setCurrentPage(parseInt(currentPage) + 1);

                        }
                      }}
                    >
                      {" "}
                      <MdOutlineChevronRight />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          ) : (
            <ContestPaymentTable
              data={payments}
              pagination={true}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />

          )}
        </>
    )
}

export default ContestPayments;