import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { LoginToken, TokenAppenditure } from "../../pages/Utility/common";
import defaultImg from "../../assets/images/icon.png";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { MdCancel } from "react-icons/md";
import config from "../../config";

const SwitchModal = (props) => {
    const {modal, setModal, data} = props;
    const [searchVal, setSearchVal] = useState("");
    const history = useHistory();
    const handleSearchVal = (e) => {
      setSearchVal(e.target.value);
    }
    const toggle = () => {
        setModal(!modal);
    }
    const loginToken = LoginToken();
    const switchAction = (ContactID) => {
        fetch(
            `${config.genni.marketing_portal.API_KEY}/creatorapi/switch`,
            { method: "POST",
              headers: { "Content-Type": "application/json", "x-api-key": loginToken },
              body: JSON.stringify({contactId: ContactID})
             }
        )
            .then((res) => {
            if (res.ok) {
                return res.json();
            }
            throw res;
            //const jsonRes = JSON.parse(res);
            })
            .then(async (data) => {
            //  if (data.Login_Information.LoginValid) {
            await Cookies.set("ContactDetail", "");
            const redirectLink = `/dashbord${TokenAppenditure()}`;
            history.push(redirectLink);
            });
    };
    return (
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader className="d-flex">
          <div className="d-flex jc-space-between al-center" style={{ flexDirection: 'column' }}>
            <h5 className="madeGentle full-width" style={{ display: "flex", justifyContent: "space-between" }}>
              Manage Creators
              <MdCancel className="cursor-pointer" onClick={toggle} />
            </h5>
            <style>{`.form-group{margin-bottom:0px;}`}</style>
            <AvForm className="full-width">
              <AvField
                name="search"
                value={searchVal}
                onChange={handleSearchVal}
                type="text"
                className="inputField mb-0 full-width"
                placeholder="Search"
                errorMessage="Invalid First Name"
              />
            </AvForm>

          </div>
        </ModalHeader>
        <ModalBody
          style={{
            maxHeight: 300,
            overflowY: "scroll",
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          {data &&
            data.results.LinkedAccounts.map((e, i) => {
              if (e.DisplayName.toLowerCase().includes(searchVal.toLowerCase())) {
                return (
                  <div
                    className="border-bottom d-flex full-width p-2"
                    style={{ justifyContent: "space-between" }}
                    onClick={() => {
                      switchAction(e.ContactID);
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", }}>
                      <div>
                        <img
                          src={e.ProfileImageUrl ? e.ProfileImageUrl : defaultImg}
                          style={{
                            height: 35,
                            width: 35,
                            borderRadius: "100%",
                            objectFit: "cover",
                            marginRight: 8,
                          }}
                        />
                        {e.UnreadMessageCount > 0 && (
                          <div
                            style={{
                              position: "absolute",
                              height: 13,
                              width: 13,
                              background: "#f853bd",
                              borderRadius: "100%",
                              top: 10,
                              left: 50,
                              border: "1px solid #fff",
                            }}
                          ></div>
                        )}
                      </div>
                      <span className="overflow-ellipsis" style={{ maxWidth: '130px' }}>
                        {e.DisplayName}
                      </span>
                      {e.InviteCount > 0 && (
                        <span className="badge bg-soft-pink rounded-pill ms-1">
                          {e.InviteCount} Invites
                        </span>
                      )}
                      {e.AccountType === "Manager" && (
                        <span className="badge bg-soft-pink rounded-pill ms-1">
                          Manager
                        </span>
                      )}
                    </div>
                    <div className="btn btn-sm btn-outline-pink p-2" style={{ maxHeight: 36, }}>
                      Login
                    </div>
                  </div>
                );
              }

            })}
        </ModalBody>
        <ModalFooter>
          <Button color="pink" onClick={() => {
            history.push(`/account/accounts/${TokenAppenditure()}`);
          }} className="">
            Manage Accounts
          </Button>
        </ModalFooter>
      </Modal>
    );
}

export default SwitchModal;