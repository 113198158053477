import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  BreadcrumbItem,
  CardBody,
} from "reactstrap";
import { Capacitor } from "@capacitor/core";
import Select from 'react-select';
import { get } from "lodash";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Cookies from "js-cookie";
import { useLocation, Link, useHistory } from "react-router-dom";
import queryString from "query-string";
import Tablelist from "../Utility/tablelist";
import CampaignTable from "../Utility/campaigntable";
import moment from "moment";
import "moment-timezone";
import HowItWorks from "./howitworks";
import OnboardingForm from "./OnboardingForm";
import { isMobile } from 'react-device-detect';
import { HiQuestionMarkCircle } from "react-icons/hi";
import { LoginToken, TokenAppenditure, Breadcrumbs, CommonLoader} from "../Utility/common";
import config from "../../config"
//Import Breadcrumb

const Campaigns = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  let params = queryString.parse(location.search);
  const [gridType, setGridType] = useState("grid");
  useEffect(() => {
    setGridType("grid");
  }, [])
  const history = useHistory();
  const [campaignType, setCampaignType] = useState({ value: "inprogress", label: 'In Progress' });
  const [tooltipMessage, setTooltipMessage] = useState(
    params.type === "failed"
      ? "Invite was accepted but no post notification was submitted within 48 hours of accepting the invite."
      : "Invite was sent but was not accepted or declined within 48 hours."
  );
  const [howItWorksModal, setHowItWorksModal] = useState(false);
  const loginToken = LoginToken();

  const [postStatusID, setPostStatusID] = useState("current");
  const [data, setData] = useState();
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [campaigns, setCampaigns] = useState();
  const [tabValue, setTabValue] = useState(0);
  const toggle = () => {

    setModal(!modal);
  }
  const calcDateValue = (d) => {
    const t = new Date("January 1 1970 00:00:00-0:00");
    t.setSeconds(t.getSeconds() + parseFloat(d));
    const momentT = moment(t).tz("America/Chicago"); // Ensures CDT
    return momentT.format("M/D/YYYY") === "Invalid date"
      ? ""
      : momentT.format("MMM D hh:mmA z");
  };

  const fetchCampaigns = () => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/campaigns`,
      { method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({status: campaignType.value, page: currentPage})
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then(async (data) => {
        // console.log("campaigns", data);
        setIsLoading(false);
        setData(data);
        setTotalPages(data.results.data.PageInformation.TotalPages);
        let campaignsFormattedArr = [];
        data.results.data.Row_Campaigns.map((e) => {
          const handle = e.Row_CampaignDetails.map((a) => {
            if (a.FieldName === "Handle") {
              return a.FieldValue;
            }
          });
          const platform = e.Row_CampaignDetails.map((a) => {
            if (a.FieldName === "Platform") {
              return a.FieldValue;
            }
          });
          const image = e.Row_CampaignDetails.map((a) => {
            if (a.FieldName === "Image") {
              return a.FieldValue;
            }
          });
          const eImage = get(image, [1], null);
          // console.log(eImage);
          if (postStatusID === "current") {
            campaignsFormattedArr.push({
              title: e.CampaignTitle,
              subtitle: `${e.PostDeadline.DeadlineText} ${e.PostDeadline.DeadlineDate
                  ? e.PostDeadline.DeadlineDateTime
                  : ""
                }`,

              price: e.PostPayment,
              handle: handle,
              platform: platform,
              postID: e.PostID,
              UID: e.UID,
              type: "Campaign",
              image: eImage ? eImage : "",
              isNew: e.IsNew,
            });
          } else {
            e.Row_CampaignDetails.map((a) => {
              if (a.FieldName === "Payment Status") {
                campaignsFormattedArr.push({
                  title: e.CampaignTitle,
                  subtitle: a.FieldValue,
                  price: e.PostPayment,
                  platform: platform,
                  handle: handle,
                  postID: e.PostID,
                  image: eImage,
                  type: "Campaign",
                  UID: e.UID,
                  isNew: e.IsNew,

                });
              }
            });
          }
        });
        setCampaigns(campaignsFormattedArr);

      }).catch((error) => {
        // console.log("Error",error);
        setCampaigns([]);
      });
  }
  useEffect(() => {
    fetchCampaigns();
  }, [campaignType, currentPage]);

  const changeCampaignType = (e) => {
    setCampaigns();
    setCurrentPage(1);
    setCampaignType(e);
  }

  const handleTabChange = (e, val) => {
    setTabValue(val);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const toggleModal = () => {
    setHowItWorksModal(!howItWorksModal);
  }

  const typeOptions = [
    { value: 'all', label: 'All' },
    { value: 'inprogress', label: 'In Progress' },
    { value: 'completed', label: 'Completed' },
    { value: 'expired', label: 'Expired' },
    { value: 'failed', label: 'Failed' },
    { value: 'pulled', label: 'Pulled' }
  ];

  const ToggleView = () => {
    return (
      <div className="d-flex mr-2 ">
        <div onClick={() => { setGridType("grid") }} className={`${gridType !== "grid" ? "bg-white" : "disabled"} bg-white border d-flex al-center jc-center cursor-pointer`} style={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4, height: 39, width: 39 }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={gridType === "grid" ? "#162638" : "#bec0c4"} stroke={gridType === "grid" ? "#162638" : "#bec0c4"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="fill-current w-4 h-4 "><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
        </div>
        <div onClick={() => { setGridType("list") }} className={`${gridType !== "list" ? "bg-white" : "disabled"} border d-flex al-center jc-center cursor-pointer`} style={{ borderWidth: 4, borderTopRightRadius: 4, borderBottomRightRadius: 4, height: 39, width: 39 }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#bec0c4" stroke={gridType !== "grid" ? "#162638" : "#bec0c4"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="fill-current w-4 h-4 "><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
        </div>
      </div>
    );
  }

  const TheTabs = () => {
    return (<Tabs value={tabValue} TabIndicatorProps={{ style: { background: "#fff" } }} onChange={handleTabChange} aria-label="basic tabs example">
      <Tab label="My Campaigns" value={0} disableRipple {...a11yProps(0)} />
      <Tab label="How It Works" value={1} disableRipple {...a11yProps(1)} />
    </Tabs>);
  }
  return (
    <React.Fragment>
      {/* <style>{Capacitor.isNativePlatform() ? `.PrivateTabIndicator-colorSecondary-3 {
        background-color: #fff !important;
        height: 3px !important;
      }
      .MuiTab-wrapper {color:#fff !important;}.MuiTabs-indicator {background-color: #fff !important;}` : `.MuiTabs-indicator {background-color: #f853bd !important;}`
}</style> */}
      {/* {Capacitor.isNativePlatform() && (
        <div
          className="card bg-white mobileTabs"
        >
          <TheTabs />
        </div>
      )}
       */}
      {Capacitor.isNativePlatform() && (
        <div className={"container pt-2 pb-2 border-bottom"} style={{ marginTop: `${Capacitor.isNativePlatform() ? '-160px' : '20px'}` }}>
          <div className="card-statistics" style={{ marginTop: '-15px' }}>
            <div className={`bg-white d-flex jc-space-between al-center align-items-center`} style={{ borderRadius: 8 }}>
              <ToggleView />
              <Select
                value={campaignType}
                defaultValue={campaignType}
                onChange={changeCampaignType}
                options={typeOptions}
                className="fancy-select full-width"
                classNamePrefix="cursor-pointer full-width"
                color="#f853bd"
                isSearchable={false}
              />

            </div>

          </div>
        </div>
      )}



      <div className="page-content">
        {Capacitor.isNativePlatform() && (<div style={{ marginTop: '-12px' }}></div>)}
        <div className="container">
          <Row className="mb-3 border-bottom">
            <Col className="col-12 mt-0">
              {Breadcrumbs("Campaigns", "Home", `/dashboard${TokenAppenditure()}`, true)}
              {!Capacitor.isNativePlatform() && (
                <div className="mb-3">
                  <div className="d-flex jc-space-between align-items-center mb-2 campaignHeader">
                    <h4 onClick={toggleModal} className="madeGentle align-items-center mb-0 campaignTitle cursor-pointer">Campaigns <HiQuestionMarkCircle style={{marginTop:'-2px'}} /></h4>
                    <div>
                      <div className="d-flex campaignSubtitle">
                        <ToggleView />
                        <Select
                          value={campaignType}
                          defaultValue={campaignType}
                          onChange={changeCampaignType}
                          options={typeOptions}
                          className="fancy-select"
                          classNamePrefix="cursor-pointer"
                          color="#f853bd"
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!Capacitor.isNativePlatform() ? (
                <div className="full-width border-bottom mb-3"></div>
              ) : (
                <div className="mb-1"></div>
              )}

              {tabValue === 0 && (
                <>

                  {!data ||
                    (data && data.results.data.ApprovalStatusID === 3) ? (
                    <>
                      {gridType === "grid" ? (
                        <Tablelist
                          data={campaigns ? campaigns : null}
                          pagination={true}
                          isDashboard={false}
                          totalPages={totalPages}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      ) : (
                        <CampaignTable
                          data={campaigns ? campaigns : null}
                          pagination={true}
                          totalPages={totalPages}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                    </>
                  ) : (
                    <div className="card shadow br-8 bg-white full-width">
                      <div
                        className="p-2 py-5"
                        style={{
                          display: "flex",
                          textAlign: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h5 className="madeGentle">Application pending.</h5>
                        <h6 className="text-muted">
                          Check back soon! Our team is reviewing your application and will email you once approved.
                        </h6>
                      </div>
                    </div>
                  )}
                </>

              )}


            </Col>
          </Row>
          <HowItWorks howItWorksModal={howItWorksModal} toggleModal={toggleModal} />
          {/* <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader className="madeGentle">
              Apply To Be Eligible For Campaigns
            </ModalHeader>
            <ModalBody>
              <OnboardingForm />
            </ModalBody>

          </Modal> */}
        </div>

      </div>

    </React.Fragment>
  );
};;

export default Campaigns;