import React, { useEffect, useState } from "react"
import config from "../../config"

const Privacy = () => {
  const app = "Genni Web App";
  const [data, setData] = useState();
  useEffect(() => {
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/legal`, {
      method: "POST",
      headers: { "Content-Type": "application/json"},
      body: JSON.stringify({ type: "Privacy Policy", application: app })

    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (!data.error) {
          setData(data);
        } else {
          // window.location.href = "/login";
        }
      });
  }, []);
  // // console.log("data", data);
  return (
    <React.Fragment>
      <div className="container">
        {data && (
          <div
            className="Archivo"
            dangerouslySetInnerHTML={{
              __html: data.results.data.Information,
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default Privacy
