// React Basic and Bootstrap
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { MdAdd, MdAlternateEmail, MdCampaign, MdDelete, MdEdit, MdHelp, MdInfo, MdInfoOutline, MdLink, MdWallet } from "react-icons/md";
import { Row, Col, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { get } from "lodash";
import EditProfileModal from "./EditProfileModal";
import EditPlatformModal from "./EditPlatformModal";
import AddPlatformModal from "./AddPlatformModal";
import {ResponseRateTooltip} from "../Utility/modals";
import { Capacitor } from "@capacitor/core";
import queryString from "query-string";
import { CommonLoader } from "../Utility/common";
import PaymentInfo from "../AccountSettings/payment"
import config from "../../config"

function Admin() {
    const location = useLocation();
    let params = queryString.parse(location.search);
    const [currentTab, setCurrentTab] = useState("Handles");
    const [editProfileModal, setEditProfileModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [editPlatformModal, setEditPlatformModal] = useState(false);
    const [addPlatformModal, setAddPlatformModal] = useState(false);
    const [selectedHandle, setSelectedHandle] = useState();
    const loginToken = Capacitor.isNativePlatform() ? params.token : Cookies.get("LoginToken");
    const [data, setData] = useState();
    const [refetch, setRefetch] = useState(0);
    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/admin?contactId=${params.ContactID}&uid=${params.UID}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                throw res;
                //const jsonRes = JSON.parse(res);
            })
            .then((data) => {
                setIsLoading(false);
                setData(data);
            });

    }, [refetch]);

    const toggleEditProfileModal = () => {
        setEditProfileModal(!editProfileModal);
    }
    const toggleEditPlatformModal = () => {
        if (editPlatformModal) {
            setSelectedHandle(null);

        }
        setEditPlatformModal(!editPlatformModal);
    }
    const toggleAddPlatformModal = () => {

        setAddPlatformModal(!addPlatformModal);
    }
    const profileInfo = get(data, ["results", "output", "ProfileInfo",], null);
    const profileImg = get(data, ["results", "output", "ProfileInfo", "Contact_ImageURLLocation"], "");
    const firstName = get(data, ["results", "output", "ProfileInfo", "FirstName"], "");
    const lastName = get(data, ["results", "output", "ProfileInfo", "LastName"], "");
    const loginUsername = get(data, ["results", "output", "ProfileInfo", "LogInUsername"], "");
    const paymentEmail = get(data, ["results", "output", "PaymentInfo", "PaymentEmail"], "");
    const address = get(data, ["results", "output", "ProfileInfo", "AddressLine1"], "");
    const city = get(data, ["results", "output", "ProfileInfo", "City"], "");
    const state = get(data, ["results", "output", "ProfileInfo", "StateID"], "");
    const country = get(data, ["results", "output", "ProfileInfo", "CountryID"], "");
    const zip = get(data, ["results", "output", "ProfileInfo", "PostalCode"], "");
    const phone = get(data, ["results", "output", "ProfileInfo", "Phone_Primary"], "");
    const birthday = get(data, ["results", "output", "ProfileInfo", "Birthday"], "");
    const statesList = get(data, ["results", "output", "States"], "");
    const countriesList = get(data, ["results", "output", "Countries"], "");
    const platformsList = get(data, ["results", "output", "Platforms"], "");
    const contactPlatforms = get(data, ["results", "output", "ContactPlatforms"], []);

    function findStateName(stateID) {
        for (let i = 0; i < statesList.length; i++) {
            if (statesList[i].StateID === stateID) {
                return statesList[i].StateName;
            }
        }
        return null; // Return null if stateID is not found
    }

    function findCountryName(countryID) {
        for (let i = 0; i < countriesList.length; i++) {
            if (countriesList[i].CountryID === countryID) {
                return countriesList[i].CountryName;
            }
        }
        return null; // Return null if stateID is not found
    }

    const returnCPMBadge = (cpm) => {
        if (parseFloat(cpm) > 3.5 && parseFloat(cpm) < 7) {
            return (<span className="badge bg-warning text-black font-size-12">
                ${cpm}
            </span>)
        } else if (parseFloat(cpm) >= 7) {
            return (<span className="badge bg-danger text-black font-size-12">
                ${cpm}
            </span>)
        } else {
            return (<span className="badge bg-success text-black font-size-12">
                ${cpm}
            </span>)
        }
    }

    const returnCPM = (row) => {
        if (row.CPM_AtCurrentPrice || row.CPM_Historical || row.CPM_Projected) {
            if (parseFloat(row.CPM_AtCurrentPrice) > 0) {
                return returnCPMBadge(row.CPM_AtCurrentPrice);
            } else if (parseFloat(row.CPM_Projected) > 0) {
                return returnCPMBadge(row.CPM_Projected);
            } else {
                return returnCPMBadge(row.CPM_Historical);
            }

        } else {
            return returnCPMBadge(0)
        }
    }

    const returnResponseRate = () => {
        if (!contactPlatforms || contactPlatforms.length === 0) {
            return null; // If the contactPlatforms is empty or does not exist, return null
        }

        const validScores = contactPlatforms.filter(obj => obj.Score_ResponseRate_Invite !== null);
        const sum = validScores.reduce((total, obj) => total + obj.Score_ResponseRate_Invite, 0);
        return sum / validScores.length ? `${sum / validScores.length}%` : "TBD";
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <div className="page-content">
                    <div className="container">
                        <CommonLoader />
                    </div>
                </div>
            ) : (
                <div className="page-content">

                    <div className="container">

                        {/* start page title */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Manage Roster</a>
                                            </li>
                                            <li className="breadcrumb-item active">Profile</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {successMessage && (
                            <>
                                <Alert color="success"><i className="mdi mdi-check-circle"></i>{successMessage}</Alert>

                            </>
                        )}
                        {/* end page title */}
                        <div className="row mb-4">
                            <div className="col-xl-4 mb-2">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="text-center">

                                            <div className="clearfix" />
                                            <div>
                                                <img
                                                    src={profileImg}
                                                    alt=""
                                                    className="avatar-lg rounded-circle img-thumbnail p-0"

                                                />
                                            </div>
                                            <h5 className="mt-3 mb-1">{`${firstName} ${lastName}`}</h5>
                                            <div className="mt-2">
                                                <button type="button" className="btn btn-light btn-sm" onClick={toggleEditProfileModal}>
                                                    <MdEdit /> Edit Info
                                                </button>
                                                {profileInfo && (
                                                    <EditProfileModal
                                                        isOpen={editProfileModal}
                                                        toggle={toggleEditProfileModal}
                                                        contactInfo={profileInfo}
                                                        states={statesList}
                                                        countries={countriesList}
                                                        setRefetch={setRefetch}
                                                        refetch={refetch}
                                                        setSuccessMessage={setSuccessMessage}
                                                    />
                                                )}

                                            </div>
                                        </div>
                                        <hr className="my-4" />
                                        <div className="text-muted">

                                            <div className="table-responsive mt-4">
                                                <div className="">
                                                    <p className="mb-1">Mobile Phone:</p>
                                                    <h5 className="font-size-16">{phone}</h5>
                                                </div>
                                                {loginUsername && (
                                                    <div className="mt-4">
                                                        <p className="mb-1">Login Email:</p>
                                                        <h5 className="font-size-16">{loginUsername}</h5>
                                                    </div>
                                                )}

                                                <div className="mt-4">
                                                    <p className="mb-1">Birthday:</p>
                                                    <h5 className="font-size-16">{birthday}</h5>
                                                </div>
                                                <div className="mt-4">
                                                    <p className="mb-1">Address:</p>
                                                    <h5 className="font-size-16">{address}</h5>
                                                </div>
                                                <div className="mt-4">
                                                    <p className="mb-1">City:</p>
                                                    <h5 className="font-size-16">{city}</h5>
                                                </div>
                                                <div className="mt-4">
                                                    <p className="mb-1">State:</p>
                                                    <h5 className="font-size-16">{findStateName(state)}</h5>
                                                </div>
                                                <div className="mt-4">
                                                    <p className="mb-1">Postal Code:</p>
                                                    <h5 className="font-size-16">{zip}</h5>
                                                </div>

                                                <div className="mt-4">
                                                    <p className="mb-1">Country:</p>
                                                    <h5 className="font-size-16">{findCountryName(country)}</h5>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="d-flex" >
                                    <div className="border card d-flex mb-2" style={{ flexDirection: 'row', padding: '1.25rem', width: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <span
                                                    className="avatar avatar-rounded bg-indigo-100 text-indigo-600 dark:bg-indigo-500/20 dark:text-indigo-100"
                                                    style={{
                                                        width: 55,
                                                        height: 55,
                                                        minWidth: 55,
                                                        fontSize: "27.5px",
                                                        background: 'rgb(224, 231, 255)',
                                                        borderRadius: 6,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <span className="avatar-icon avatar-icon-55" style={{ lineHeight: 0 }} >
                                                        <svg
                                                            stroke="rgb(79, 70, 229)"
                                                            fill="rgb(79, 70, 229)"
                                                            strokeWidth={0}
                                                            viewBox="0 0 20 20"
                                                            height="1em"
                                                            width="1em"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </span>
                                                </span>
                                                <div className="mx-4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <div className="d-flex" style={{ flexDirection: 'row', }}>
                                                        <div className="d-flex">
                                                            <h4 className="font-bold leading-none m-0 madeGentle">{returnResponseRate()} </h4>

                                                            {/* <span className="badge bg-danger mx-2" style={{lineHeight:1.7}}>
                                                            Not good!
                                                        </span> */}
                                                        </div>
                                                    </div>
                                                    <p className="flex items-center gap-1 mb-0">
                                                        <span>Response Rate</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={{ fontSize: 22, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                <ResponseRateTooltip />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-0">
                                    {/* Nav tabs */}
                                    <ul
                                        className="nav nav-tabs nav-tabs-custom nav-justified"
                                        role="tablist"
                                    >
                                        <li className="nav-item cursor-pointer" role="presentation" onClick={() => { setCurrentTab("Handles") }}>
                                            <a
                                                className={`nav-link ${currentTab === "Handles" && 'active'}`}
                                                style={{ background: '#fff' }}
                                                data-bs-toggle="tab"
                                                role="tab"
                                                aria-selected="true"
                                            >
                                                <MdAlternateEmail size={16} />
                                                <span className="d-none d-sm-block">Handles</span>
                                            </a>
                                        </li>
                                        <li className="nav-item cursor-pointer" role="presentation" onClick={() => { setCurrentTab("Payment Info") }}>
                                            <a
                                                className={`nav-link ${currentTab === "Payment Info" && 'active'}`}
                                                style={{ background: '#fff' }}
                                                data-bs-toggle="tab"
                                                role="tab"
                                                aria-selected="true"
                                            >
                                                <MdWallet size={16} />
                                                <span className="d-none d-sm-block">Payment Info</span>
                                            </a>
                                        </li>
                                    </ul>
                                    {/* Tab content */}
                                    <div className="tab-content p-4">
                                        {currentTab === "Handles" && (
                                            <div className="tab-pane active" id="handles" role="tabpanel">
                                                <div>
                                                    {selectedHandle && (
                                                        <EditPlatformModal
                                                            isOpen={editPlatformModal}
                                                            toggle={toggleEditPlatformModal}
                                                            key={selectedHandle}
                                                            selectedHandle={selectedHandle}
                                                            setSelectedHandle={setSelectedHandle}
                                                            setRefetch={setRefetch}
                                                            refetch={refetch}
                                                            platforms={platformsList}
                                                            setSuccessMessage={setSuccessMessage}
                                                        />)
                                                    }
                                                    <div>
                                                        <div className="flex d-flex al-center jc-space-between mb-4">
                                                            <h5 className="mb-0 madeGentle">Handles</h5>
                                                            <div className="btn btn-pink btn-sm"
                                                                onClick={()=>{setAddPlatformModal(!addPlatformModal)}}
                                                            >
                                                                <MdAdd /> Add Handle</div>
                                                            </div>
                                                        <div className="table-responsive">
                                                            <table className="table table-nowrap table-hover mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Platform</th>
                                                                        <th scope="col">Handle</th>
                                                                        <th scope="col">Song Rate</th>
                                                                        <th scope="col">Brand Rate</th>
                                                                        {/* <th scope="col">
                                                                            CPM
                                                                        </th> */}
                                                                        <th scope="col">
                                                                            Edit
                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {contactPlatforms && contactPlatforms.map(e => {
                                                                        return (
                                                                            <tr>
                                                                                <th scope="row">{e.PlatformName}</th>
                                                                                <td>
                                                                                    {e.Handle}
                                                                                </td>
                                                                                <td>${e.Accounting_PostNegotiatedRate}</td>
                                                                                <td>${e.Accounting_PostNegotiatedBrandRate}</td>
                                                                                {/* <td>
                                                                                    {returnCPM(e)}
                                                                                </td> */}
                                                                                <td>
                                                                                    <div className="bg-light btn-light btn btn-sm" onClick={() => { setSelectedHandle(e); toggleEditPlatformModal(); }}>
                                                                                        <MdEdit /> Edit
                                                                                    </div>
                                                                                </td>

                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {currentTab === "Payment Info" && (
                                            <>
                                                {loginUsername ? (
                                                    <>
                                                        <div className="page-title-box d-flex flex-col">
                                                            <h4 className="mb-0 madeGentle">Payment Information</h4>
                                                            <p className="text-muted">To update this creator's payment information, please login to their account with the email '{loginUsername}' or press the login button on the manage roster page.</p>
                                                        </div>
                                                        <div className="campaigns">

                                                            <div className="campaign">
                                                                <div className="campaign-title">
                                                                    <h6 className="text-black ">PayPal Email</h6>
                                                                </div>
                                                                <AvForm>
                                                                    <AvField
                                                                        name="PayPalEmail"
                                                                        value={paymentEmail}
                                                                        disabled
                                                                        type="text"
                                                                        className="inputField mb-3 "
                                                                        errorMessage="Invalid Email"
                                                                    />
                                                                </AvForm>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <PaymentInfo isAdminPage={true} />

                                                )}
                                            </>
                                        )}


                                        <AddPlatformModal
                                            isOpen={addPlatformModal}
                                            toggle={toggleAddPlatformModal}
                                            setRefetch={setRefetch}
                                            refetch={refetch}
                                            platforms={platformsList}
                                            setSelectedHandle={setSuccessMessage}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end row */}
                    </div>
                </div>
            )}


        </React.Fragment>
    );
}

export default Admin;