import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as moment from "moment";
import { Container,Alert } from "reactstrap";
import {Capacitor} from "@capacitor/core";
import { LoginToken } from "../Utility/common";
import config from "../../config";

function range(start, thisYear) {
  const arr = [];
  for (let index = start; index < thisYear; index++) {
    arr.push(index);
  }
  return arr;
}

const Feedback = () => {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");

  const [senderEmail, setSenderEmail] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();
  const [error, setError] = useState("");
  let params = queryString.parse(location.search);
  const loginToken = LoginToken();
  const [data, setData] = useState();
  // console.log(params.PostID);


  // console.log(data);
  const handleToChange = (e) => {
    setTo(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const sendFeedback = () => {
    fetch(
      `${config.genni.marketing_portal.API_KEY}/creatorapi/feedback-submit`,
      { method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({feedback: message})
       }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        setData(data.results);

      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {!data ? (
          <Container className="bg-white p-3 card">
            <p
              className="madeGentle pb-1"
              style={{ fontSize: 24, marginBottom: 0 }}
            >
              Give Us Feedback
            </p>
            <AvForm>
              <div className="campaigns">
                <div className="campaign mt-3">
                  <div className="campaign-title">
                    <h6 className="">Feedback</h6>
                  </div>
                  <textarea
                    className="inputField Archivo"
                    name="message"
                    style={{ minWidth: "100%" }}
                    value={message}
                    onChange={handleMessageChange}
                  ></textarea>
                </div>
                <div className="campaign">
                  <p className="text-danger">{error}</p>
                  <div
                    className="btn btn-pink gradient-pink full-width float-right"
                    style={{ width: "100%" }}
                    onClick={sendFeedback}
                  >
                    Submit
                  </div>
                </div>
              </div>
            </AvForm>
          </Container>
        ) : (
          <Container className="bg-white p-3 card">
            <Alert color="success">{data.message}</Alert>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export default Feedback;
