import React, { useEffect, useState } from "react";
import {  useLocation } from "react-router-dom";
import queryString from "query-string";
import Navbar from "./Navbar";
import Cookies from "js-cookie";
import Header from "./Header";
import { TokenAppenditure, LoginToken } from "../../pages/Utility/common";
import config from "../../config";

const FullHeader = ({singleHeader}) => {
  const loginToken = LoginToken(); // => 'value'
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`${config.genni.marketing_portal.API_KEY}/creatorapi/switch`, {
      method: "GET",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          // window.location.href = "/login";
        }
      })
      .then(async (data) => {
        // console.log("Switch",data);
        setIsLoading(false);
        await Cookies.set("ContactDetail", JSON.stringify(data.contactDetail));
        setData(data);

      })

  }, []);
  return (
    <React.Fragment>
      <header>

        <Header theme="dark" isMenuOpened={false} data={data} isLoading={isLoading}></Header>

        {!singleHeader && (
          <Navbar menuOpen={false} home={false} data={data} />
        )}

      </header>
    </React.Fragment>
  );
};

export default FullHeader;